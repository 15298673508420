import { userService } from "../_services";

const setState = () => {
  return {
    yourDanceCompApp: {},
    allDanceCompApps: {},
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  getDanceCompApp({ commit, dispatch }, id) {
    commit("getDanceCompAppRequest");

    userService.getDanceCompApp(id).then(
      (danceCompApp) => commit("getDanceCompAppSuccess", danceCompApp),
      (error) => {
        commit("getDanceCompAppFailure", error);
      }
    );
  },
  getAllDanceCompApps({ commit, dispatch }) {
    commit("getAllRequest");

    userService.getAllDanceCompApps().then(
      (users) => commit("getAllSuccess", users),
      (error) => {
        commit("getAllFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  submitDanceCompApp({ commit, dispatch }, danceCompApp) {
    commit("submitDanceCompAppRequest", danceCompApp);

    userService.submitDanceCompApp(danceCompApp).then(
      (danceApp) => {
        //if the danceCompName size parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        if (danceCompApp.dancerName)
          commit("submitDanceCompAppSuccess", danceApp);
        else commit("updateDanceCompAppSuccess", danceApp);
        let msg =
          "Application " +
          (danceCompApp.dancerName ? "submitted" : "updated") +
          " successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("submitDanceCompAppFailure", {
          danceCompApp,
          error: error.toString(),
        });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
};

const mutations = {
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  updateDancerName(state, value) {
    state.yourDanceCompApp.dancerName = value;
  },
  updateIntro(state, value) {
    state.yourDanceCompApp.intro = value;
  },
  getDanceCompAppRequest(state) {
    state.yourDanceCompApp = { loading: true };
  },
  getDanceCompAppSuccess(state, danceCompApp) {
    if (danceCompApp) {
      state.yourDanceCompApp = danceCompApp;
    } else state.yourDanceCompApp = {};
  },
  getDanceCompAppFailure(state, error) {
    state.yourDanceCompApp = {};
  },
  getAllRequest(state) {
    state.allDanceCompApps = { loading: true };
  },
  getAllSuccess(state, danceCompApps) {
    state.allDanceCompApps = { items: danceCompApps };
  },
  getAllFailure(state, error) {
    state.allDanceCompApps = { ...state.allDanceCompApps, error };
  },
  submitDanceCompAppRequest(state, danceCompApp) {
    state.yourDanceCompApp = { ...state.yourDanceCompApp, submitting: true };
  },
  submitDanceCompAppSuccess(state, danceCompApp) {
    state.yourDanceCompApp = danceCompApp;
  },
  updateDanceCompAppSuccess(state, danceCompApp) {
    state.allDanceCompApps.items = state.allDanceCompApps.items.map((app) => {
      if (app.attendeeID == danceCompApp.attendeeID) {
        return { ...app, ...danceCompApp };
      }
      return app;
    });
  },
  submitDanceCompAppFailure(state, error) {
    state.yourDanceCompApp = { ...state.yourDanceCompApp, error };
  },
};

export const dancecomp = {
  namespaced: true,
  state,
  actions,
  mutations,
};
