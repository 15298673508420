import { userService } from "../_services";
import { getField, updateField } from "vuex-map-fields";

const setState = () => {
  return {
    yourAlphaRecord: null,
    allAlphas: {},
    alphaBadgeName: null,
    alphaShoeSize: {
      attendeeID: null,
      shoeSize: null,
      shoeSizeType: null,
      badgeName: null,
    },
    alphaTickets: 0,
    vrs: {
      loading: false,
      submitting: false,
    },
    readyToProcessPayment: false,
    paymentError: false,
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  getAlphaShoe({ commit }, id) {
    commit("getAlphaShoeRequest");
    userService.getShoe(id).then(
      (alpha) => commit("getAlphaShoeSuccess", alpha),
      (error) => commit("getAlphaShoeFailure", error)
    );
  },
  submitAlphaShoe({ commit, dispatch }, shoeSize) {
    commit("submitAlphaShoeRequest");
    userService.submitShoe(shoeSize).then(
      (alpha) => {
        commit("submitAlphaShoeSuccess", alpha);
        dispatch("alert/success", "Updated successfully!", {
          root: true,
        });
      },
      (error) => {
        commit("submitAlphaShoeFailure", { shoeSize, error: error.toString() });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  getAlpha({ commit }, id) {
    commit("getAlphaRequest");

    userService.getAlpha(id).then(
      (alpha) => commit("getAlphaSuccess", alpha),
      (error) => commit("getAlphaFailure", error)
    );
  },
  getAllAlphas({ commit, dispatch }) {
    commit("getAllRequest");

    userService.getAllAlphas().then(
      (users) => commit("getAllSuccess", users),
      (error) => {
        commit("getAllFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  submitDinner({ commit, dispatch }, dinner) {
    commit("submitDinnerRequest", dinner);
    userService.submitDinner(dinner).then(
      (alpha) => {
        commit("submitDinnerSuccess", alpha);
        dispatch("alert/success", "Updated successfully!", {
          root: true,
        });
      },
      (error) => {
        commit("submitDinnerFailure", { dinner, error: error.toString() });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  submitCheckIn({ commit, dispatch }, dinner) {
    commit("submitCheckInRequest", dinner);

    userService.submitDinner(dinner).then(
      (alpha) => {
        commit("submitCheckInSuccess", alpha);
        dispatch("alert/success", "Updated successfully!", {
          root: true,
        });
      },
      (error) => {
        commit("submitCheckInFailure", { dinner, error: error.toString() });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  finalizeTicket({ commit, dispatch }, dinner) {
    commit("finalizeTicketRequest", dinner);

    userService.submitDinner(dinner).then(
      (alpha) => {
        commit("finalizeTicketSuccess", alpha);
        dispatch("alert/success", "Updated successfully!", {
          root: true,
        });
      },
      (error) => {
        commit("finalizeTicketFailure", { dinner, error: error.toString() });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
};

const getters = {
  getField,
};

const mutations = {
  updateField,
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  getAlphaShoeRequest(state) {
    state.vrs.loading = true;
  },
  getAlphaShoeSuccess(state, alpha) {
    state.alphaShoeSize = alpha;
    state.alphaShoeSize.shoeSize = null;
    state.vrs.loading = false;
  },
  getAlphaShoeFailure(state, error) {
    state.vrs.loading = false;
  },
  submitAlphaShoeRequest(state) {
    state.vrs.submitting = true;
  },
  submitAlphaShoeSuccess(state, alpha) {
    state.alphaShoeSize.shoeSizeType = alpha;
    state.vrs.submitting = false;
  },
  submitAlphaShoeFailure(state, error) {
    state.vrs.submitting = false;
  },
  getAlphaRequest(state) {
    state.yourAlphaRecord = { loading: true };
  },
  getAlphaSuccess(state, alpha) {
    state.yourAlphaRecord = alpha;
  },
  getAlphaFailure(state, error) {
    state.yourAlphaRecord = {};
  },
  getAllRequest(state) {
    state.allAlphas = { loading: true };
  },
  getAllSuccess(state, alphas) {
    state.allAlphas = { items: alphas };
  },
  getAllFailure(state, error) {
    state.allAlphas = { ...state.allAlphas, error };
  },
  finalizeTicketRequest(state) {},
  finalizeTicketSuccess(state, alphas) {},
  finalizeTicketFailure(state, error) {},
  submitCheckInRequest(state, alpha) {
    state.allAlphas = { ...state.allAlphas };
  },
  submitCheckInSuccess(state, alpha) {
    state.allAlphas.items = state.allAlphas.items.map((alp) => {
      if (alp.attendeeID == alpha.attendeeID) {
        return { ...alp, ...alpha };
      }
      return alp;
    });
  },
  submitCheckInFailure(state, error) {
    state.allAlphas = { ...state.allAlphas, error };
  },
  submitDinnerRequest(state, alpha) {
    state.yourAlphaRecord = { ...state.yourAlphaRecord, loading: true };
  },
  submitDinnerSuccess(state, alpha) {
    state.yourAlphaRecord = alpha;
  },
  submitDinnerFailure(state, error) {
    state.yourAlphaRecord = { ...state.yourAlphaRecord, error };
  },
  updateDinnerChoices(state, value) {
    state.yourAlphaRecord.dinnerChoices = value;
  },
  updateSeatingChoices(state, value) {
    state.yourAlphaRecord.seatingChoices = value;
  },
};

export const alpha = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
