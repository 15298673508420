import config from "config";
import { authHeader } from "../_helpers";

export const charityService = {
  runDrawing,
  markNoShows,
  getWinners,
  confirmWinner,
  getCasinoApp,
  deleteCasinoApp,
  getAllCasinoApps,
  submitCasinoApp,
};

function runDrawing() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/charity/casino/drawing`, requestOptions).then(
    handleResponse
  );
}

function getWinners() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/charity/casino/winners`, requestOptions).then(
    handleResponse
  );
}

function confirmWinner(casino) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(casino),
  };
  return fetch(`${config.apiUrl}/charity/casino/drawing`, requestOptions)
    .then(handleResponse)
    .then((dealer) => {
      return dealer;
    });
}

function markNoShows(group) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(group),
  };
  return fetch(`${config.apiUrl}/charity/casino/noshows`, requestOptions)
    .then(handleResponse)
    .then((banana) => {
      return banana;
    });
}

function getCasinoApp() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/charity/casino/app`, requestOptions).then(
    handleResponse
  );
}

function deleteCasinoApp() {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
    credentials: "include",
  };
  return fetch(`${config.apiUrl}/charity/casino/app`, requestOptions).then(
    handleResponse
  );
}

function getAllCasinoApps() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/charity/casino/app/all`, requestOptions).then(
    handleResponse
  );
}

function submitCasinoApp(casino) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(casino),
  };
  return fetch(`${config.apiUrl}/charity/casino/app`, requestOptions)
    .then(handleResponse)
    .then((dealer) => {
      return dealer;
    });
}

function handleNonTextResponse(response) {
  if (!response.ok) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      const error = (data && data.message) || response.statusText;
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      //console.clear();
      return Promise.reject(error);
    });
  }
  return response;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      //console.clear();
      return Promise.reject(error);
    }

    return data;
  });
}
