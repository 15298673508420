import config from "config";
import { authHeader } from "../_helpers";

export const hotelService = {
  getHotelApp,
  deleteHotelApp,
  getRoommate,
  getAllHotelApps,
  submitHotelApp,
};

function getHotelApp() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/hotel/app`, requestOptions).then(
    handleResponse
  );
}

function deleteHotelApp() {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
    credentials: "include",
  };
  return fetch(`${config.apiUrl}/hotel/app`, requestOptions).then(
    handleResponse
  );
}

function getRoommate(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };

  return fetch(`${config.apiUrl}/hotel/roommate`, requestOptions).then(
    handleResponse
  );
}

function getAllHotelApps() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/hotel/app/all`, requestOptions).then(
    handleResponse
  );
}

function submitHotelApp(hotel) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(hotel),
  };
  return fetch(`${config.apiUrl}/hotel/app`, requestOptions)
    .then(handleResponse)
    .then((dealer) => {
      return dealer;
    });
}

function handleNonTextResponse(response) {
  if (!response.ok) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      const error = (data && data.message) || response.statusText;
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      //console.clear();
      return Promise.reject(error);
    });
  }
  return response;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      //console.clear();
      return Promise.reject(error);
    }

    return data;
  });
}
