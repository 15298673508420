import config from "config";
import { authHeader } from "../_helpers";

export const togglzService = {
  getTogglz,
  saveTogglz,
  getConSetting,
  saveConSetting,
};

function getTogglz() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/togglz`, requestOptions).then(handleResponse);
}

function saveTogglz(toggle) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(toggle),
  };
  return fetch(`${config.apiUrl}/togglz`, requestOptions)
    .then(handleResponse)
    .then((banana) => {
      return banana;
    });
}

function getConSetting() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/conSetting`, requestOptions).then(
    handleResponse
  );
}

function saveConSetting(conSetting) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(conSetting),
  };
  return fetch(`${config.apiUrl}/conSetting`, requestOptions)
    .then(handleResponse)
    .then((banana) => {
      return banana;
    });
}

function handleNonTextResponse(response) {
  if (!response.ok) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      const error = (data && data.message) || response.statusText;
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      //console.clear();
      return Promise.reject(error);
    });
  }
  return response;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      //console.clear();
      return Promise.reject(error);
    }

    return data;
  });
}
