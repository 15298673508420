import { createStore } from "vuex";
import { alert } from "./alert.module";
import { account } from "./account.module";
import { users } from "./users.module";
import { alpha } from "./alphas.module";
import { volunteer } from "./volunteers.module";
import { dancecomp } from "./dancecomp.module";
import { musicfestival } from "./musicfestival.module";
import { dj } from "./djs.module";
import { panel } from "./panels.module";
import { charity } from "./charity.module";
import { files } from "./files.module";
import { rewards } from "./rewards.module";
import { payment } from "./payment.module";
import { register } from "./register.module";
import { dealer } from "./dealers.module";
import { hotel } from "./hotel.module";
import { togglz } from "./togglz.module";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
    account,
    users,
    alpha,
    volunteer,
    dancecomp,
    musicfestival,
    dj,
    panel,
    charity,
    files,
    rewards,
    payment,
    register,
    dealer,
    hotel,
    togglz,
  },
});
