import { createRouter, createWebHistory } from "vue-router";
import store from "../_store/index";

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

/*import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Alpha from "../views/Alpha.vue";
import Volunteer from "../views/Volunteer.vue";
import DJ from "../views/DJ.vue";
import Dancecomp from "../views/Dancecomp.vue";
import Panels from "../views/Panels.vue";
import Charity from "../views/Charity.vue";
import Minors from "../views/Minors.vue";
import Rewards from "../views/Rewards.vue";
import Register from "../views/Register.vue";
import Settings from "../views/Settings.vue";
import CheckIn from "../views/CheckIn.vue";
import Privacy from "../views/Privacy.vue";
import AlphaAdmin from "../views/AlphaAdmin.vue";
import VolunteerAppAdmin from "../views/VolunteerAppAdmin.vue";
import MinorAdmin from "../views/MinorAdmin.vue";
import PanelAdmin from "../views/PanelAdmin.vue";
import CharityAdmin from "../views/CharityAdmin.vue";
import DanceAdmin from "../views/DanceAdmin.vue";
import DJAdmin from "../views/DJAdmin.vue";*/
import Shoes from "../views/Shoes.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: lazyLoad("Home"),
  },
  {
    path: "/login",
    name: "Login",
    component: lazyLoad("Login"),
  },
  {
    path: "/alpha",
    name: "Alpha",
    component: lazyLoad("Alpha"),
  },
  {
    path: "/hotel",
    name: "Hotel",
    component: lazyLoad("Hotel"),
  },
  {
    path: "/volunteer",
    name: "Volunteer",
    component: lazyLoad("Volunteer"),
  },
  {
    path: "/dj",
    name: "DJ",
    component: lazyLoad("DJ"),
  },
  {
    path: "/dancecomp",
    name: "Fursuit Dance Competition",
    component: lazyLoad("Dancecomp"),
  },
  {
    path: "/musicfestival",
    name: "Music Festival",
    component: lazyLoad("Musicfestival"),
  },
  {
    path: "/panels",
    name: "Panels",
    component: lazyLoad("Panels"),
  },
  {
    path: "/charity",
    name: "Charity",
    component: lazyLoad("Charity"),
  },
  {
    path: "/minors",
    name: "Minors",
    component: lazyLoad("Minors"),
  },
  {
    path: "/rewards",
    name: "Rewards",
    component: lazyLoad("Rewards"),
  },
  /*{
    path: "/shoes/:attendeeID/",
    name: "Shoes",
    component: Shoes,
  },*/
  {
    path: "/register",
    name: "Register",
    component: lazyLoad("Register"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: lazyLoad("Settings"),
  },
  {
    path: "/checkin",
    name: "CheckIn",
    component: lazyLoad("CheckIn"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: lazyLoad("Privacy"),
  },
  {
    path: "/admin/alpha",
    name: "Alpha Admin",
    component: lazyLoad("AlphaAdmin"),
  },
  {
    path: "/admin/volunteerapp",
    name: "Volunteer Admin",
    component: lazyLoad("VolunteerAppAdmin"),
  },
  {
    path: "/admin/minor",
    name: "Minor Admin",
    component: lazyLoad("MinorAdmin"),
  },
  {
    path: "/admin/rewards",
    name: "Rewards Admin",
    component: lazyLoad("RewardsAdmin"),
  },
  {
    path: "/admin/panel",
    name: "Panel Admin",
    component: lazyLoad("PanelAdmin"),
  },
  {
    path: "/admin/charity",
    name: "Charity Admin",
    component: lazyLoad("CharityAdmin"),
  },
  {
    path: "/admin/dance",
    name: "Dance Admin",
    component: lazyLoad("DanceAdmin"),
  },
  {
    path: "/admin/musicfestival",
    name: "Music Festival Admin",
    component: lazyLoad("MusicFestAdmin"),
  },
  {
    path: "/admin/dealer",
    name: "Dealer Admin",
    component: lazyLoad("DealerAdmin"),
  },
  {
    path: "/admin/dj",
    name: "DJ Admin",
    component: lazyLoad("DJAdmin"),
  },
  {
    path: "/admin/reg",
    name: "Registration Admin",
    component: lazyLoad("RegAdmin"),
  },
  {
    path: "/admin/merch",
    name: "Merch Admin",
    component: lazyLoad("MerchAdmin"),
  },
  // RAWR this is for when settings get migrated from main.js to db
  // {
  //   path: "/admin/conSettings",
  //   name: "Convention Settings",
  //   component: lazyLoad("ConSettings"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/privacy", "/register", "/shoes"];
  // const authRequired = !publicPages.includes(to.path);
  const authRequired = !publicPages.some((d) => to.path.includes(d));
  const loggedIn = document.cookie.match(
    RegExp("(?:^|;\\s*)" + "loginToken" + "=([^;]*)")
  );

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});

export default router;
