import { userService } from "../_services";
import { getField, updateField } from "vuex-map-fields";

const setState = () => {
  return {
    vrs: {
      newCohostDOB: "2000-01-01",
      newCohostUsername: "",
      loading: false,
      submitting: false,
      cohosts: {},
    },
    yourPanelApps: {},
    allpanelapps: {},
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  getPanelApps({ commit }) {
    commit("getPanelAppRequest");

    userService.getPanelApp().then(
      (panelApp) => commit("getPanelAppSuccess", panelApp),
      (error) => commit("getPanelAppFailure", error)
    );
  },
  getAllPanelApps({ commit }) {
    commit("getAllRequest");

    userService.getAllPanelApps().then(
      (users) => commit("getAllSuccess", users),
      (error) => commit("getAllFailure", error)
    );
  },
  deletePanelApp({ commit, dispatch }, id) {
    commit("deletePanelAppRequest");

    userService.deletePanelApp(id).then(
      (panelApp) => {
        let msg = "Entry deleted";
        dispatch("alert/success", msg, {
          root: true,
        });
        commit("deletePanelAppSuccess", panelApp);
      },
      (error) => {
        commit("deletePanelAppFailure", error);
      }
    );
  },
  submitPanelApp({ commit, dispatch }, panelApp) {
    commit("submitPanelAppRequest", panelApp);

    userService.submitPanelApp(panelApp).then(
      (panApp) => {
        //if the preferredName parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        if (panelApp.preferredName) commit("submitPanelAppSuccess", panApp);
        else commit("updatePanelAppSuccess", panApp);
        let msg =
          "Application " +
          (panelApp.preferredName ? "submitted" : "updated") +
          " successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("submitPanelAppFailure", {
          panelApp,
          error: error.toString(),
        });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  getCohost({ commit, dispatch, state }, va) {
    commit("getCohostRequest");

    userService.getCohost(va.user).then(
      (mate) => {
        if (
          state.vrs.cohosts[va.id ? va.id : "x"] &&
          state.vrs.cohosts[va.id ? va.id : "x"].some((q) => q.id == mate.id)
        ) {
          dispatch("alert/error", "You've already added that person.", {
            root: true,
          });
        } else commit("getCohostSuccess", { cohost: mate, id: va.id });
      },
      (error) => {
        dispatch("alert/error", error, {
          root: true,
        });
        commit("getCohostFailure", error);
      }
    );
  },
};

const getters = {
  getField,
};

const mutations = {
  updateField,
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  updateEventTitle(state, value) {
    state.yourPanelApps.eventTitle = value;
  },
  updatePreferredName(state, value) {
    state.yourPanelApps.preferredName = value;
  },
  updateEventType(state, value) {
    state.yourPanelApps.eventType = value;
  },
  updateEventDesc(state, value) {
    state.yourPanelApps.eventDesc = value;
  },
  updateExtraTime(state, value) {
    state.yourPanelApps.extraTime = value;
  },
  updatePreferredDay(state, value) {
    state.yourPanelApps.preferredDay = value;
  },
  updateSpecialNeeds(state, value) {
    state.yourPanelApps.specialNeeds = value;
  },
  getPanelAppRequest(state) {
    state.vrs.loading = true;
  },
  getPanelAppSuccess(state, panelApp) {
    state.yourPanelApps = { items: panelApp.apps };
    state.vrs.loading = false;
    state.vrs.cohosts = panelApp.cohosts;
  },
  getPanelAppFailure(state, error) {
    state.yourPanelApps = {};
    state.vrs.loading = false;
  },
  getCohostRequest(state) {
    state.vrs.submitting = true;
    state.vrs.newCohostDOB = "2000-01-01";
    state.vrs.newCohostUsername = "";
  },
  getCohostSuccess(state, incoming) {
    //if (!state.yourPanelApps.roommates) state.yourHotelApp.roommates = [];
    if (state.vrs.cohosts[incoming.id])
      state.vrs.cohosts[incoming.id].push(incoming.cohost);
    else if (state.vrs.cohosts["x"])
      state.vrs.cohosts["x"].push(incoming.cohost);
    else state.vrs.cohosts["x"] = [{ ...incoming.cohost }];

    state.vrs.submitting = false;
  },
  getCohostFailure(state, error) {
    state.vrs.submitting = false;
  },
  getAllRequest(state) {
    state.allpanelapps = { loading: true };
  },
  getAllSuccess(state, panelApps) {
    state.allpanelapps = { items: panelApps, loading: false };
  },
  getAllFailure(state, error) {
    state.allpanelapps = { ...state.allpanelapps, error, loading: false };
  },
  submitPanelAppRequest(state, panelApp) {
    state.yourPanelApps = {
      ...state.yourPanelApps,
      submitting: true,
      submitted: false,
    };
  },
  submitPanelAppSuccess(state, panelApp) {
    state.yourPanelApps = {
      items: panelApp.apps,
      submitting: false,
      submitted: true,
    };
    state.vrs.cohosts = panelApp.cohosts;
  },
  submitPanelAppFailure(state, error) {
    state.yourPanelApps = {
      ...state.yourPanelApps,
      error,
      submitting: false,
      submitted: false,
    };
  },
  deletePanelAppRequest(state) {
    state.vrs.submitting = true;
  },
  deletePanelAppSuccess(state, panelApp) {
    state.yourPanelApps = {
      items: panelApp.apps,
      submitting: false,
      submitted: true,
    };
    state.vrs.cohosts = panelApp.cohosts;
    state.vrs.submitting = false;
  },
  deletePanelAppFailure(state, error) {
    state.vrs.submitting = false;
  },
  updatePanelAppSuccess(state, panelApp) {
    state.allpanelapps.items = state.allpanelapps.items.map((app) => {
      if (app.id == panelApp.id) {
        return { ...app, ...panelApp };
      }
      return app;
    });
  },
};

export const panel = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
