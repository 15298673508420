<template>
  <div class="homemain">
    <transition name="fade" mode="out-in">
      <main v-if="loading">
        <div class="d-flex align-items-center justify-content-center">
          <div class="loading-dual-ring"></div>
        </div>
      </main>

      <main v-else-if="!loading && !canView" class="p-3">
        <div class="stdBorder">
          <h3 class="h4 fw-bold text-center">
            This form is for Alpha Members only.
          </h3>
        </div>
      </main>

      <main v-else-if="!loading && canView && shoeSizeType == 'cm'" class="p-3">
        <div class="stdBorder">
          <h3 class="h4 fw-bold text-center">
            You've submitted this form.<br />Thanks!
          </h3>
          <p class="text-center">
            To make edits again, <router-link to="/">log in</router-link> and
            edit&nbsp;your&nbsp;registration.
          </p>
        </div>
      </main>

      <div
        class="stdBorder text-center"
        v-else-if="!loading && canView && shoeSizeType != 'cm'"
      >
        Sorry, the deadline has passed and you can no longer update this
        information.
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { useVuelidate } from "@vuelidate/core";
import {
  helpers,
  required,
  alphaNum,
  not,
  numeric,
  sameAs,
  requiredIf,
  minValue,
  maxValue,
} from "@vuelidate/validators";

export default {
  name: "Shoes",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      shoeSize: {
        sameAs: helpers.withMessage("Foot length is required", required),
        shoomin: helpers.withMessage(
          "Value must be between 16.0 and 31.3",
          minValue(16.0)
        ),
        shoomax: helpers.withMessage(
          "Value must be between 16.0 and 31.3",
          maxValue(31.3)
        ),
      },
    };
  },
  data() {
    return {};
  },
  computed: {
    ...mapFields("alpha", [
      "alphaShoeSize.shoeSize",
      "alphaShoeSize.shoeSizeType",
      "alphaShoeSize.attendeeID",
      "alphaShoeSize.badgeName",
      "vrs.loading",
      "vrs.submitting",
    ]),
    canView() {
      return this.attendeeID;
    },
  },
  created() {
    if (this.$route.params.attendeeID)
      this.getAlphaShoe(this.$route.params.attendeeID);
  },
  methods: {
    ...mapActions("alpha", {
      getAlphaShoe: "getAlphaShoe",
      submitAlphaShoe: "submitAlphaShoe",
    }),

    async updateShoeSize(e) {
      var proceed = await this.v$.$validate();
      const { attendeeID, shoeSize } = this;

      this.submitAlphaShoe({
        attendeeID,
        shoeSize,
      });

      this.v$.$reset();
    },
  },
};
</script>

<style scoped></style>
