import { userService } from "../_services";
import router from "../router";

const setState = () => {
  return { status: {}, user: null };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  hello() {
    userService.hello().then(
      (hi) => {},
      (error) => {}
    );
  },
  login({ dispatch, commit }, { username, password }) {
    commit("loginRequest", { username });

    userService.login(username, password).then(
      (user) => {
        commit("loginSuccess", user);
        router.push("/");
      },
      (error) => {
        commit("loginFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  logout({ commit, dispatch }, hasToken) {
    if (hasToken) userService.logout();
    commit("resetStateNow");
    dispatch("alpha/resetState", "", { root: true });
    dispatch("charity/resetState", "", { root: true });
    dispatch("dancecomp/resetState", "", { root: true });
    dispatch("dealer/resetState", "", { root: true });
    dispatch("dj/resetState", "", { root: true });
    dispatch("files/resetState", "", { root: true });
    dispatch("hotel/resetState", "", { root: true });
    dispatch("panel/resetState", "", { root: true });
    dispatch("payment/resetState", "", { root: true });
    dispatch("register/resetState", "", { root: true });
    dispatch("rewards/resetState", "", { root: true });
    dispatch("togglz/resetState", "", { root: true });
    dispatch("users/resetState", "", { root: true });
    dispatch("volunteer/resetState", "", { root: true });
    commit("logout");
  },
  reauth({ dispatch, commit }, token) {
    userService.reauth(token).then(
      (user) => {
        commit("loginSuccess", user);
      },
      (error) => {
        commit("loginFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  register({ dispatch, commit }, user) {
    commit("registerRequest", user);

    userService.register(user).then(
      (user) => {
        commit("registerSuccess", user);
        router.push("/login");
        setTimeout(() => {
          // display success message after route change completes
          dispatch("alert/success", "Registration successful", { root: true });
        });
      },
      (error) => {
        commit("registerFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  update({ dispatch, commit }, user) {
    commit("updateRequest", user);

    userService.update(user).then(
      (user) => {
        commit("updateSuccess", user);
        dispatch("alert/success", "Updated successfully!", {
          root: true,
        });
      },
      (error) => {
        commit("updateFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  recover({ dispatch, commit }, user) {
    commit("recoverRequest", user);

    userService.recover(user).then(
      (user) => {
        commit("recoverSuccess", user);
        dispatch("alert/success", "Password recovery email sent.", {
          root: true,
        });
      },
      (error) => {
        commit("recoverFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  deleteAccount({ dispatch, commit }, pass) {
    commit("deleteRequest", pass);

    userService.deleteAccount(pass).then(
      (user) => {
        commit("deleteSuccess", user);
        dispatch("alert/success", "Account deleted. Redirecting...", {
          root: true,
        });
      },
      (error) => {
        commit("deleteFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
};

const mutations = {
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  registerRequest(state, user) {
    state.status = { registering: true };
  },
  registerSuccess(state, user) {
    state.status = { registered: true };
  },
  registerFailure(state, error) {
    state.status = {};
  },
  updateRequest(state, user) {
    state.status = { updating: true };
  },
  updateSuccess(state, user) {
    state.status = { updated: true };
    state.user = user;
  },
  updateFailure(state, error) {
    state.status = {};
  },
  recoverRequest(state, user) {
    state.status = { recovering: true };
  },
  recoverSuccess(state, user) {
    state.status = { recovered: true };
    state.user = user;
  },
  recoverFailure(state, error) {
    state.status = {};
  },
  deleteRequest(state, user) {
    state.status = { deleting: true };
  },
  deleteSuccess(state, user) {
    state.status = { deleted: true };
    state.user = null;
  },
  deleteFailure(state, error) {
    state.status = {};
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
};
