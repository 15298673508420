import { registerService } from "../_services";
import { getField, updateField } from "vuex-map-fields";

const setState = () => {
  return {
    load: false,
    submitting: false,
    allRegistrations: [],
    regStats: {
      labels: [],
      datasets: [],
    },
    yourRegistration: {},
    dealerRegistration: {},
    assistant: [],
    registrants: [],
    currentRegistration: {
      accessOK: false,
      initFail: false,
      loading: false,
      registrantNumber: undefined,
      addressSuggestions: undefined,
      confirmedAddress: undefined,
      addressAutofilled: false,
      addressSuggestionsLoading: false,
      addressConfirmationLoading: false,
      addressDeliverabilityLoading: false,
      idDeliverabilityError: "",
      mailingDeliverabilityError: "",
      currentAutofillType: "",
      currentConfirmationType: "",
      currentDeliverabilityType: "",
      phNumValid: undefined,
      pendingVouchers: [],
      alphasAvailable: false,
      voucherTarget: 0,
      regCodeSent: false,
      regCodeSuccess: false,
      readyToProcessPayment: false,
      noCostPaymentComplete: false,
      paymentError: false,
      existingLoading: false,
    },
    currentRegistrant: {
      attendeeID: undefined,
      firstName: "",
      lastName: "",
      dob: "",
      email: "",
      idCountry: "US",
      idRegion: "",
      idAddress: "",
      idAddressInitial: false,
      idAddressConfirmed: false,
      idStreet: "",
      idUnit: "",
      idCity: "",
      idPostal: "",
      mailingCountry: "US",
      mailingRegion: "",
      mailingAddress: "",
      mailingAddressInitial: false,
      mailingAddressConfirmed: false,
      mailingStreet: "",
      mailingUnit: "",
      mailingCity: "",
      mailingPostal: "",
      badgeMailingOptions: undefined,
      upgradedMailing: false,
      badgeName: "",
      species: "",
      signature: undefined,
      username: "",
      usernameAvailable: undefined,
      phoneNumber: "",
      policies: {
        dress1: false,
        dress2: false,
        dress3: false,
        dress4: false,
        dress5: false,
        dress6: false,
        dress7: false,
        dress8: false,
        dress9: false,
        covid1: false,
        covid2: false,
        offender1: false,
        harassment1: false,
        property1: false,
        laws1: false,
      },
      registrationOption: "Basic",
      tShirtSize: undefined,
      tShirtCut: undefined,
      beltSize: undefined,
      shoeSize: 1, // original value: undefined
      shoeSizeType: "cm",
      alphaName: undefined,
      lagoonTicket: false,
      dealerApplicant: false,
      telegramHandle: "",
      businessName: "",
      businessDesc: "",
      businessLinks: [""],
      specialRequests: "",
      dealerTableOptions: "",
      tableOption: 1,
      voucherUsed: undefined,
      noReg: false,
      isLeader: false,
      noPolicy: false,
      waitingPool: false,
      dealerAgreement: false,
      dealerPolicies: {
        dealerContent1: false,
        dealerContent2: false,
        dealerContent3: false,
        dealerContent4: false,
        dealerFood1: false,
        dealerFood2: false,
        dealerMisc1: false,
        dealerMisc2: false,
        dealerSpace1: false,
        dealerTax1: false,
        dealerTax2: false,
      },
    },
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  getAll({ commit, dispatch }) {
    commit("getAllRequest");

    registerService.getAll().then(
      (users) => commit("getAllSuccess", users),
      (error) => {
        commit("getAllFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  getStats({ commit, dispatch }) {
    commit("getStatsRequest");

    registerService.getStats().then(
      (users) => commit("getStatsSuccess", users),
      (error) => {
        commit("getStatsFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  hello() {
    registerService.hello().then(
      (hi) => {},
      (error) => {}
    );
  },

  initial({ commit, dispatch }) {
    registerService.initial().then(
      (hi) => commit("initSuccess", hi),
      (error) => {
        commit("initFail", error);
      }
    );
  },

  checkUsername({ commit, state }, params) {
    commit("checkUsernameRequest");

    if (
      state.registrants.some(
        (v) => v.username.toLowerCase() == params.username.toLowerCase()
      )
    )
      commit("checkUsernameFailure");
    else {
      registerService.checkUsername(params).then(
        (username) => commit("checkUsernameSuccess", username),
        (error) => commit("checkUsernameFailure", error)
      );
    }
  },

  checkAlphas({ commit }) {
    commit("checkAlphasRequest");

    registerService.checkAlphas().then(
      (result) => commit("checkAlphasSuccess", result),
      (error) => commit("checkAlphasFailure", error)
    );
  },

  addressAutofill({ commit }, params) {
    commit("addressAutofillRequest", params.type);

    registerService.addressAutofill(params).then(
      (username) => {
        username.type = params.type;
        commit("addressAutofillSuccess", username);
      },
      (error) => {
        let errors = {};
        errors.addressType = params.type;
        errors.error = error;
        commit("addressAutofillFailure", errors);
      }
    );
  },

  addressConfirmation({ commit }, params) {
    commit("addressConfirmationRequest", params.type);
    registerService.addressConfirmation(params).then(
      (username) => {
        username.type = params.type;
        commit("addressConfirmationSuccess", username);
      },
      (error) => {
        let errors = {};
        errors.addressType = params.type;
        errors.error = error;
        commit("addressConfirmationFailure", errors);
      }
    );
  },

  addressDeliverability({ commit }, params) {
    commit("addressDeliverabilityRequest", params.type);
    registerService.addressDeliverability(params).then(
      (username) => {
        username.type = params.type;
        username.original = params.original;
        commit("addressDeliverabilitySuccess", username);
      },
      (error) => {
        let errors = {};
        errors.addressType = params.type;
        errors.error = error;
        commit("addressDeliverabilityFailure", error);
      }
    );
  },

  adminSubmitReg({ commit, dispatch }, params) {
    commit("adminSubmitRegRequest");

    registerService.adminSubmitReg(params).then(
      (username) => {
        commit("adminSubmitRegSuccess", username);
      },
      (error) => {
        commit("adminSubmitRegFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  submitReg({ commit, dispatch }, params) {
    commit("submitRegRequest", params.type);

    registerService.submitReg(params).then(
      (username) => {
        username = { noCost: params.noCost, other: username };
        if (params.currentAttID) {
          dispatch("register/pullExistingRegistration", params.currentAttID, {
            root: true,
          });
          dispatch("alpha/getAlpha", params.currentAttID, { root: true });
          dispatch("account/reauth", "", { root: true });
        }
        commit("submitRegSuccess", username);
      },
      (error) => {
        commit("submitRegFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  testReg({ commit, dispatch }, params) {
    commit("testRegRequest", params.type);

    registerService.testReg(params).then(
      (username) => {
        commit("testRegSuccess", username);
      },
      (error) => {
        commit("testRegFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  pedoCheck({ commit, dispatch }, params) {
    console.log("register.module.js function");
    commit("pedoCheckRequest", params.type);

    registerService.pedoCheck(params).then(
      (username) => {
        commit("pedoCheckSuccess", username);
      },
      (error) => {
        commit("pedoCheckFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  cancelRegistration({ commit, dispatch }, params) {
    commit("submitRefundRequest");
    console.log("Register Module cancelRegistration", params);
    registerService.cancelRegistration(params).then(
      (params) => {
        commit("submitRefundSuccess", params);
        let msg = "Reg Cancelled Successfully";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("submitRefundFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  submitVoucher({ commit, dispatch }, params) {
    commit("submitVoucherRequest");

    if (
      state.registrants
        .filter((e) => e.voucherUsed)
        .some((v) => v.voucherUsed.code == params.code)
    ) {
      commit(
        "submitVoucherFailure",
        "You've already used that voucher on someone else."
      );
      dispatch(
        "alert/error",
        "You've already used that voucher on someone else.",
        {
          root: true,
        }
      );
    } else {
      registerService.submitVoucher(params).then(
        (username) => {
          username.who = params.who;
          commit("submitVoucherSuccess", username);
        },
        (error) => {
          commit("submitVoucherFailure", error);
          dispatch("alert/error", error, {
            root: true,
          });
        }
      );
    }
  },

  sendRegistrationCode({ commit, dispatch }, params) {
    commit("sendRegistrationCodeRequest");

    registerService.sendRegistrationCode(params).then(
      (username) => {
        commit("sendRegistrationCodeSuccess", username);
      },
      (error) => {
        commit("sendRegistrationCodeFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  verifyRegistrationCode({ commit, dispatch }, params) {
    commit("verifyRegistrationCodeRequest");

    registerService.verifyRegistrationCode(params).then(
      (username) => {
        commit("verifyRegistrationCodeSuccess", username);
      },
      (error) => {
        commit("verifyRegistrationCodeFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  updateCR({ commit }, params) {
    commit("updateCurrentRegistrant", params);
  },

  pullExistingRegistration({ commit, dispatch }, params) {
    commit("pullExistingRegistrationRequest");

    registerService.pullExistingRegistration(params).then(
      (username) => {
        commit("pullExistingRegistrationSuccess", username);
      },
      (error) => {
        commit("pullExistingRegistrationFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
};

const getters = {
  getField,
};

const mutations = {
  updateField,
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  updateCurrentRegistrant(state, value) {
    state.currentRegistrant = value;
  },
  checkUsernameRequest(state) {
    state.currentRegistrant.usernameAvailable = undefined;
    state.currentRegistration.loading = true;
  },
  checkUsernameSuccess(state, pint) {
    state.currentRegistration.loading = false;
    state.currentRegistrant.usernameAvailable = true;
  },
  checkUsernameFailure(state, error) {
    state.currentRegistration.loading = false;
    state.currentRegistrant.usernameAvailable = false;
  },
  getStatsRequest(state) {
    // console.log('request');
    state.load = true;
  },
  getStatsSuccess(state, pint) {
    // console.log(pint.filter(q => q.conyear == 2023));
    const seventeen = pint.filter((q) => q.conyear == 2017);
    const eighteen = pint.filter((q) => q.conyear == 2018);
    const nineteen = pint.filter((q) => q.conyear == 2019);
    const twenty = pint.filter((q) => q.conyear == 2020);
    const twentyTwo = pint.filter((q) => q.conyear == 2022);
    const twentyThree = pint.filter((q) => q.conyear == 2023);
    const twentyFour = pint.filter((q) => q.conyear == 2024);
    state.regStats = {
      labels: twentyTwo.map((n) => n.regday), // currently the longest reg-open period
      datasets: [
        {
          label: "2017",
          pointRadius: 0,
          pointHitRadius: 5,
          borderColor: "#2f782f",
          backgroundColor: "#2f782f",
          data: seventeen.map((j) => j.attendees),
        },
        {
          label: "2018",
          pointRadius: 0,
          pointHitRadius: 5,
          borderColor: "#d68d4c",
          backgroundColor: "#d68d4c",
          data: eighteen.map((j) => j.attendees),
        },
        {
          label: "2019",
          pointRadius: 0,
          pointHitRadius: 5,
          borderColor: "#dacaa2",
          backgroundColor: "#dacaa2",
          data: nineteen.map((j) => j.attendees),
        },
        {
          label: "2020",
          pointRadius: 0,
          pointHitRadius: 5,
          borderColor: "#000000",
          backgroundColor: "#000000",
          data: twenty.map((j) => j.attendees),
        },
        {
          label: "2022",
          pointRadius: 0,
          pointHitRadius: 5,
          borderColor: "#409462",
          backgroundColor: "#409462",
          data: twentyTwo.map((j) => j.attendees),
        },
        {
          label: "2023",
          pointRadius: 0,
          pointHitRadius: 5,
          borderColor: "#f0dc91",
          backgroundColor: "#f0dc91",
          data: twentyThree.map((j) => j.attendees),
        },
        {
          label: "2024",
          pointRadius: 0,
          pointHitRadius: 5,
          borderColor: "#200799",
          backgroundColor: "#200799",
          data: twentyFour.map((j) => j.attendees),
        },
      ],
    };
    state.load = false;
  },
  getStatsFailure(state, error) {
    state.load = false;
  },
  checkAlphasRequest(state) {
    state.currentRegistration.loading = true;
  },
  checkAlphasSuccess(state, pint) {
    state.currentRegistration.loading = false;
    state.currentRegistration.alphasAvailable = pint;
  },
  checkAlphasFailure(state, error) {
    state.currentRegistration.loading = false;
    state.currentRegistration.alphasAvailable = false;
  },
  addressAutofillRequest(state, type) {
    state.currentRegistration.currentAutofillType = type;
    state.currentRegistration.addressSuggestionsLoading = true;
    state.currentRegistration.idDeliverabilityError = "";
    state.currentRegistration.mailingDeliverabilityError = "";
  },
  addressAutofillSuccess(state, pint) {
    state.currentRegistration.addressSuggestionsLoading = false;
    state.currentRegistration.addressSuggestions = pint.results;
    state.currentRegistration.addressAutofilled = true;
  },
  addressAutofillFailure(state, error) {
    state.currentRegistration.addressSuggestionsLoading = false;
    state.currentRegistration.addressAutofilled = false;
  },
  addressConfirmationRequest(state, type) {
    state.currentRegistration.currentConfirmationType = type;
    state.currentRegistration.addressConfirmationLoading = true;
    state.currentRegistration.currentAutofillType = "";
  },
  addressConfirmationSuccess(state, pint) {
    state.currentRegistration.currentConfirmationType = "";
    state.currentRegistration.addressConfirmationLoading = false;
    state.currentRegistration.addressSuggestions = undefined;
    state.currentRegistration.confirmedAddress = pint;
    state.currentRegistrant[pint.type + "City"] = pint.city;
    state.currentRegistrant[pint.type + "Postal"] = pint.postalcode;
    state.currentRegistrant[pint.type + "Street"] =
      pint.formattedaddress.split(",")[0];
    state.currentRegistrant[pint.type + "AddressInitial"] = true;
  },
  addressConfirmationFailure(state, error) {
    state.currentRegistration.currentConfirmationType = "";
    state.currentRegistration.addressConfirmationLoading = false;
    state.currentRegistration.addressSuggestions = undefined;
    state.currentRegistrant[error.addressType + "AddressConfirmed"] = false;
    state.currentRegistrant[error.addressType + "AddressInitial"] = false;
  },
  addressDeliverabilityRequest(state, type) {
    state.currentRegistration.currentDeliverabilityType = type;
    state.currentRegistration.addressDeliverabilityLoading = true;
  },
  addressDeliverabilitySuccess(state, pint) {
    state.currentRegistration.currentDeliverabilityType = "";
    state.currentRegistration.addressDeliverabilityLoading = false;
    if (pint.status == "VALID") {
      state.currentRegistration.confirmedAddress = pint;
      state.currentRegistrant[pint.type + "Address"] = pint.formattedaddress;
      state.currentRegistrant[pint.type + "Street"] =
        pint.formattedaddress.split(",")[0];
      state.currentRegistrant[pint.type + "Address"] = pint.formattedaddress;
      state.currentRegistrant[pint.type + "Unit"] = pint.supplement
        ? pint.supplement
        : "";
      state.currentRegistrant[pint.type + "City"] = pint.city;
      state.currentRegistrant[pint.type + "Postal"] = pint.postalcode;

      state.currentRegistrant[pint.type + "Region"] = pint.original;
      state.currentRegistrant[pint.type + "Country"] = pint.country;
      state.currentRegistrant[pint.type + "AddressConfirmed"] = true;
    } else if (pint.status == "SUSPECT") {
      state.currentRegistration.confirmedAddress = pint;
      state.currentRegistrant[pint.type + "Address"] = pint.formattedaddress;
      state.currentRegistrant[pint.type + "Street"] =
        pint.formattedaddress.split(",")[0];
      state.currentRegistrant[pint.type + "Address"] = pint.formattedaddress;
      state.currentRegistrant[pint.type + "Unit"] = pint.supplement
        ? pint.supplement
        : "";
      state.currentRegistrant[pint.type + "City"] = pint.city;
      state.currentRegistrant[pint.type + "Postal"] = pint.postalcode;
      state.currentRegistrant[pint.type + "Region"] = pint.original;
      state.currentRegistrant[pint.type + "Country"] = pint.country;
      state.currentRegistrant[pint.type + "AddressConfirmed"] = true;
      state.currentRegistration[pint.type + "DeliverabilityError"] =
        "Your address has been updated to reflect postal system suggestions.";
    } else {
      state.currentRegistration[pint.type + "DeliverabilityError"] =
        "The postal system has this address marked as invalid or undeliverable. Please update to a valid address and try again.";
      state.currentRegistrant[pint.type + "AddressConfirmed"] = false;
    }
  },
  addressDeliverabilityFailure(state, error) {
    state.currentRegistration.currentDeliverabilityType = "";
    state.currentRegistration.addressDeliverabilityLoading = false;
    state.currentRegistrant[error.addressType + "AddressConfirmed"] = false;
  },
  testRegRequest(state) {
    state.currentRegistration.loading = true;
  },
  testRegSuccess(state, pint) {
    state.currentRegistration.readyToProcessPayment = true;
    state.currentRegistration.loading = false;
  },
  testRegFailure(state, error) {
    state.currentRegistration.loading = false;
    state.currentRegistration.readyToProcessPayment = false;
  },
  pedoCheckRequest(state) {
    state.currentRegistration.loading = true;
  },
  pedoCheckSuccess(state, pint) {
    state.currentRegistration.loading = false;
  },
  pedoCheckFailure(state, error) {
    state.currentRegistration.loading = false;
  },
  adminSubmitRegRequest(state) {
    state.submitting = true;
  },
  adminSubmitRegSuccess(state, pint) {
    state.allRegistrations.items = state.allRegistrations.items.map((app) => {
      if (app.attendeeID == pint.attendeeID) {
        return { ...app, ...pint };
      }
      return app;
    });
    state.submitting = false;
  },
  adminSubmitRegFailure(state, error) {
    state.submitting = false;
  },
  submitRegRequest(state) {
    state.currentRegistration.loading = true;
  },
  submitRegSuccess(state, pint) {
    state.currentRegistration.loading = false;
    state.currentRegistration.readyToProcessPayment = false;
    if (pint.noCost) state.currentRegistration.noCostPaymentComplete = true;
    //if (Object.keys(pint.other).length) state.yourRegistration = pint.other;
  },
  submitRegFailure(state, error) {
    state.currentRegistration.loading = false;
    state.currentRegistration.readyToProcessPayment = false;
  },
  submitRefundRequest(state) {
    state.currentRegistration.loading = true;
  },
  submitRefundSuccess(state, pint) {
    state.currentRegistration.loading = false;
    state.currentRegistration.readyToProcessPayment = false;
    if (pint.noCost) state.currentRegistration.noCostPaymentComplete = true;
    //if (Object.keys(pint.other).length) state.yourRegistration = pint.other;
  },
  submitRefundFailure(state, error) {
    state.currentRegistration.loading = false;
    state.currentRegistration.readyToProcessPayment = false;
  },
  submitVoucherRequest(state) {
    state.currentRegistration.loading = true;
  },
  submitVoucherSuccess(state, pint) {
    state.currentRegistration.loading = false;
    if (pint.who == "test") {
      state.currentRegistration.accessOK = true;
      return;
    }

    if (pint.type == 6) {
      if (pint.who == "current") {
        state.currentRegistrant.registrationOption = "Alpha";
        state.currentRegistrant.voucherUsed = pint;
      } else {
        if (state.registrants[pint.who].registrationOption == "Alpha") {
          state.registrants[pint.who].voucherUsed = pint;
        } else {
          this.dispatch(
            "alert/error",
            "That voucher is for a different membership tier. Select the correct tier, then apply the voucher.",
            {
              root: true,
            }
          );
        }
      }
    } else if (pint.type == 4) {
      if (pint.who == "current") {
        state.currentRegistrant.registrationOption = "Ultimate";
        state.currentRegistrant.voucherUsed = pint;
      } else {
        if (state.registrants[pint.who].registrationOption == "Ultimate") {
          state.registrants[pint.who].voucherUsed = pint;
        } else {
          this.dispatch(
            "alert/error",
            "That voucher is for a different membership tier. Select the correct tier, then apply the voucher.",
            {
              root: true,
            }
          );
        }
      }
    } else if (pint.type == 2) {
      if (pint.who == "current") {
        state.currentRegistrant.registrationOption = "Basic";
        state.currentRegistrant.voucherUsed = pint;
      } else {
        if (state.registrants[pint.who].registrationOption == "Basic") {
          state.registrants[pint.who].voucherUsed = pint;
        } else {
          this.dispatch(
            "alert/error",
            "That voucher is for a different membership tier. Select the correct tier, then apply the voucher.",
            {
              root: true,
            }
          );
        }
      }
    } else if (pint.type == 21) {
      if (pint.who == "current") {
        state.currentRegistrant.registrationOption = "Basic";
        state.currentRegistrant.tableOption = 1;
        state.currentRegistrant.dealerApplicant = true;
        state.currentRegistrant.voucherUsed = pint;
      } else {
        if (
          state.registrants[pint.who].registrationOption == "Basic" &&
          state.registrants[pint.who].tableOption == 1 &&
          state.registrants[pint.who].dealerApplicant == true
        ) {
          state.registrants[pint.who].voucherUsed = pint;
        } else {
          this.dispatch(
            "alert/error",
            "That voucher is for a different membership tier or table type. Select the correct options, then apply the voucher.",
            {
              root: true,
            }
          );
        }
      }
    } else if (pint.type == 41) {
      if (pint.who == "current") {
        state.currentRegistrant.registrationOption = "Ultimate";
        state.currentRegistrant.tableOption = 1;
        state.currentRegistrant.dealerApplicant = true;
        state.currentRegistrant.voucherUsed = pint;
      } else {
        if (
          state.registrants[pint.who].registrationOption == "Ultimate" &&
          state.registrants[pint.who].tableOption == 1 &&
          state.registrants[pint.who].dealerApplicant == true
        ) {
          state.registrants[pint.who].voucherUsed = pint;
        } else {
          this.dispatch(
            "alert/error",
            "That voucher is for a different membership tier or table type. Select the correct options, then apply the voucher.",
            {
              root: true,
            }
          );
        }
      }
    } else if (pint.type == 61) {
      if (pint.who == "current") {
        state.currentRegistrant.registrationOption = "Alpha";
        state.currentRegistrant.tableOption = 1;
        state.currentRegistrant.dealerApplicant = true;
        state.currentRegistrant.voucherUsed = pint;
      } else {
        if (
          state.registrants[pint.who].registrationOption == "Alpha" &&
          state.registrants[pint.who].tableOption == 1 &&
          state.registrants[pint.who].dealerApplicant == true
        ) {
          state.registrants[pint.who].voucherUsed = pint;
        } else {
          this.dispatch(
            "alert/error",
            "That voucher is for a different membership tier or table type. Select the correct options, then apply the voucher.",
            {
              root: true,
            }
          );
        }
      }
    }
  },
  submitVoucherFailure(state, error) {
    state.currentRegistration.loading = false;
  },
  sendRegistrationCodeRequest(state) {
    state.currentRegistration.loading = true;
    state.currentRegistration.regCodeSent = false;
  },
  sendRegistrationCodeSuccess(state, pint) {
    state.currentRegistration.loading = false;
    state.currentRegistration.regCodeSent = true;
  },
  sendRegistrationCodeFailure(state, error) {
    state.currentRegistration.loading = false;
    state.currentRegistration.regCodeSent = false;
  },
  verifyRegistrationCodeRequest(state) {
    state.currentRegistration.loading = true;
  },
  verifyRegistrationCodeSuccess(state, pint) {
    state.currentRegistration.loading = false;
    state.currentRegistration.regCodeSent = false;
    state.currentRegistration.regCodeSuccess = true;
    state.currentRegistrant = {
      ...state.currentRegistrant,
      usernameAvailable: true,
      ...pint,
    };
  },
  verifyRegistrationCodeFailure(state, error) {
    state.currentRegistration.loading = false;
  },
  initSuccess(state, pint) {
    state.currentRegistration.initFail = false;
  },
  initFail(state, error) {
    state.currentRegistration.initFail = true;
  },
  pullExistingRegistrationRequest(state) {
    state.currentRegistration.existingLoading = true;
  },
  pullExistingRegistrationSuccess(state, pint) {
    state.yourRegistration = pint.base;
    state.dealerRegistration = pint.dealer;
    state.assistant = pint.assistant;
    state.currentRegistration.existingLoading = false;
  },
  pullExistingRegistrationFailure(state, error) {
    state.currentRegistration.existingLoading = false;
  },
  getAllRequest(state) {
    state.load = true;
  },
  getAllSuccess(state, pint) {
    state.allRegistrations.items = pint;
    state.load = false;
  },
  getAllFailure(state, error) {
    state.load = false;
  },
};

export const register = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
