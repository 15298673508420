import { paymentService } from "../_services";

const setState = () => {
  return {
    yourPayment: undefined,
    customerID: undefined,
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  createPaymentIntent({ commit }, params) {
    commit("createPaymentIntentRequest");
    paymentService.createPaymentIntent(params).then(
      (pint) => commit("createPaymentIntentSuccess", pint),
      (error) => commit("createPaymentIntentFailure", error)
    );
  },
};

const mutations = {
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  createPaymentIntentRequest(state) {
    state.yourPayment = { loading: true };
  },
  createPaymentIntentSuccess(state, pint) {
    state.yourPayment = pint;
    state.customerID = pint.customer;
  },
  createPaymentIntentFailure(state, error) {
    state.yourPayment = {};
  },
};

export const payment = {
  namespaced: true,
  state,
  actions,
  mutations,
};
