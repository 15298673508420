import { userService } from "../_services";

const setState = () => {
  return {
    all: [],
    rewardsUser: {},
    rewardsActivity: {},
    redeemedRewards: [],
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  getRewardsUser({ commit }, person) {
    commit("getRewardsUserRequest");
    userService.getRewardsUser(person).then(
      (user) => commit("getRewardsUserSuccess", user),
      (error) => commit("getRewardsUserFailure", error)
    );
  },
  saveRewardsUser({ commit }, person) {
    commit("saveRewardsUserRequest");
    userService.saveRewardsUser(person).then(
      (user) => commit("saveRewardsUserSuccess", user),
      (error) => commit("saveRewardsUserFailure", error)
    );
  },
  getRewards({ commit }, person) {
    commit("getRewardsActivityRequest");
    userService.getRewards(person).then(
      (rewardsActivity) => commit("getRewardsActivitySuccess", rewardsActivity),
      (error) => commit("getRewardsActivityFailure", error)
    );
  },
  saveReward({ commit }, reward) {
    commit("saveRewardsActivityRequest");
    userService.saveReward(reward).then(
      (rewardr) => commit("saveRewardsActivitySuccess", rewardr),
      (error) => commit("saveRewardsActivityFailure", error)
    );
  },
  getRedemptionActivity({ commit }, person) {
    commit("getRedemptionActivityRequest");
    userService.getRedemptionActivity(person).then(
      (rewardsActivity) =>
        commit("getRedemptionActivitySuccess", rewardsActivity),
      (error) => commit("getRedemptionActivityFailure", error)
    );
  },
  redeemReward({ commit, dispatch }, reward) {
    commit("redeemRewardRequest");
    userService.redeemReward(reward).then(
      (redemption) => {
        commit("redeemRewardSuccess", redemption);
        dispatch(
          "alert/success",
          "Reward redeemed! Check your history tab for details.",
          {
            root: true,
          }
        );

        commit("getRewardsActivityRequest");
        userService.getRewards().then(
          (rewardsActivity) =>
            commit("getRewardsActivitySuccess", rewardsActivity),
          (error) => commit("getRewardsActivityFailure", error)
        );
      },
      (error) => commit("redeemRewardFailure", error)
    );
    //after we redeem a reward, the rewards activity must be updated too
  },
  transferPoints({ commit, dispatch }, reward) {
    commit("transferPointsRequest");
    userService.transferPoints(reward).then(
      (transfer) => {
        commit("transferPointsSuccess", reward);
        dispatch("alert/success", "Points transferred successfully.", {
          root: true,
        });
        commit("getRewardsActivityRequest");
        userService.getRewards().then(
          (rewardsActivity) =>
            commit("getRewardsActivitySuccess", rewardsActivity),
          (error) => commit("getRewardsActivityFailure", error)
        );
      },
      (error) => {
        commit("transferPointsFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
    //after we transfer points, the rewards activity must be updated too
  },

  adminGetRewardsUser({ commit }, person) {
    commit("adminGetRewardsUserRequest");
    userService.adminGetRewardsUser(person).then(
      (user) => commit("adminGetRewardsUserSuccess", user),
      (error) => commit("adminGetRewardsUserFailure", error)
    );
  },
  adminSaveRewardsUser({ commit }, person) {
    commit("adminSaveRewardsUserRequest");
    userService.adminSaveRewardsUser(person).then(
      (user) => commit("adminSaveRewardsUserSuccess", user),
      (error) => commit("adminSaveRewardsUserFailure", error)
    );
  },
  adminGetRewards({ commit }, person) {
    commit("adminGetRewardsActivityRequest");
    userService.adminGetRewards(person).then(
      (rewardsActivity) =>
        commit("adminGetRewardsActivitySuccess", rewardsActivity),
      (error) => commit("adminGetRewardsActivityFailure", error)
    );
  },
  adminSaveReward({ commit }, reward) {
    commit("adminSaveRewardsActivityRequest");
    userService.adminSaveReward(reward).then(
      (rewardr) => commit("adminSaveRewardsActivitySuccess", rewardr),
      (error) => commit("adminSaveRewardsActivityFailure", error)
    );
  },
  adminGetRedemptionActivity({ commit }, person) {
    commit("adminGetRedemptionActivityRequest");
    userService.adminGetRedemptionActivity(person).then(
      (rewardsActivity) =>
        commit("adminGetRedemptionActivitySuccess", rewardsActivity),
      (error) => commit("adminGetRedemptionActivityFailure", error)
    );
  },
  adminRedeemReward({ commit, dispatch }, reward) {
    commit("adminRedeemRewardRequest");
    userService.adminRedeemReward(reward).then(
      (redemption) => {
        commit("adminRedeemRewardSuccess", redemption);
        dispatch(
          "alert/success",
          "Reward redeemed! Check your history tab for details.",
          {
            root: true,
          }
        );
        commit("adminGetRewardsActivityRequest");
        userService.adminGetRewards().then(
          (rewardsActivity) =>
            commit("adminGetRewardsActivitySuccess", rewardsActivity),
          (error) => commit("adminGetRewardsActivityFailure", error)
        );
      },
      (error) => commit("adminRedeemRewardFailure", error)
    );
    //after we redeem a reward, the rewards activity must be updated too
  },
  adminTransferPoints({ commit, dispatch }, reward) {
    commit("adminTransferPointsRequest");
    userService.adminTransferPoints(reward).then(
      (transfer) => {
        commit("adminTransferPointsSuccess", reward);
        dispatch("alert/success", "Points transferred successfully.", {
          root: true,
        });
        commit("adminGetRewardsActivityRequest");
        userService.adminGetRewards().then(
          (rewardsActivity) =>
            commit("adminGetRewardsActivitySuccess", rewardsActivity),
          (error) => commit("adminGetRewardsActivityFailure", error)
        );
      },
      (error) => {
        commit("adminTransferPointsFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
    //after we transfer points, the rewards activity must be updated too
  },
};

const mutations = {
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  getRewardsUserRequest(state, user) {
    state.rewardsUser = { ...state.rewardsUser, loading: true };
  },
  getRewardsUserSuccess(state, user) {
    state.rewardsUser = user;
  },
  getRewardsUserFailure(state, error) {
    state.rewardsUser = { ...state.rewardsUser, error: error, loading: false };
  },
  saveRewardsUserRequest(state, user) {
    state.rewardsUser = { ...state.rewardsUser, saving: true };
  },
  saveRewardsUserSuccess(state, user) {
    state.rewardsUser = user;
  },
  saveRewardsUserFailure(state, error) {
    state.rewardsUser = { ...state.rewardsUser, error: error, saving: false };
  },
  getRewardsActivityRequest(state, rewardsActivity) {
    state.rewardsActivity = { ...state.rewardsActivity, loading: true };
  },
  getRewardsActivitySuccess(state, rewardsActivity) {
    state.rewardsActivity = { items: rewardsActivity.reverse() };
  },
  getRewardsActivityFailure(state, error) {
    state.rewardsActivity = {
      ...state.rewardsActivity,
      error: error,
      loading: false,
    };
  },
  saveRewardsActivityRequest(state, reward) {
    state.rewardsActivity = { ...state.rewardsActivity, saving: true };
  },
  saveRewardsActivitySuccess(state, reward) {
    state.rewardsActivity = { items: reward };
  },
  saveRewardsActivityFailure(state, error) {
    state.rewardsActivity = {
      ...state.rewardsActivity,
      error: error,
      saving: false,
    };
  },
  getRedemptionActivityRequest(state, redemptionActivity) {
    state.redeemedRewards = { ...state.redeemedRewards, loading: true };
  },
  getRedemptionActivitySuccess(state, redemptionActivity) {
    state.redeemedRewards = { items: redemptionActivity.reverse() };
  },
  getRedemptionActivityFailure(state, error) {
    state.redeemedRewards = {
      ...state.redeemedRewards,
      error: error,
      loading: false,
    };
  },
  redeemRewardRequest(state, reward) {
    state.redeemedRewards = { ...state.redeemedRewards, saving: true };
  },
  redeemRewardSuccess(state, reward) {
    state.rewardsUser.current += reward.pointValue;
    state.redeemedRewards.items.push(reward);
    state.redeemedRewards = { ...state.redeemedRewards, saving: false };
  },
  redeemRewardFailure(state, error) {
    state.redeemedRewards = {
      ...state.redeemedRewards,
      error: error,
      saving: false,
    };
  },
  transferPointsRequest(state, reward) {
    state.rewardsActivity = { ...state.rewardsActivity, saving: true };
  },
  transferPointsSuccess(state, transfer) {
    state.rewardsUser.current += transfer.value;
    state.rewardsActivity = { ...state.rewardsActivity };
  },
  transferPointsFailure(state, error) {
    state.rewardsActivity = {
      ...state.rewardsActivity,
      error: error,
      saving: false,
    };
  },

  adminGetRewardsUserRequest(state, user) {
    state.rewardsUser = { ...state.rewardsUser, loading: true };
  },
  adminGetRewardsUserSuccess(state, user) {
    state.rewardsUser = user;
  },
  adminGetRewardsUserFailure(state, error) {
    state.rewardsUser = { ...state.rewardsUser, error: error, loading: false };
  },
  adminSaveRewardsUserRequest(state, user) {
    state.rewardsUser = { ...state.rewardsUser, saving: true };
  },
  adminSaveRewardsUserSuccess(state, user) {
    state.rewardsUser = user;
  },
  adminSaveRewardsUserFailure(state, error) {
    state.rewardsUser = { ...state.rewardsUser, error: error, saving: false };
  },
  adminGetRewardsActivityRequest(state, rewardsActivity) {
    state.rewardsActivity = { ...state.rewardsActivity, loading: true };
  },
  adminGetRewardsActivitySuccess(state, rewardsActivity) {
    state.rewardsActivity = { items: rewardsActivity.reverse() };
  },
  adminGetRewardsActivityFailure(state, error) {
    state.rewardsActivity = {
      ...state.rewardsActivity,
      error: error,
      loading: false,
    };
  },
  adminSaveRewardsActivityRequest(state, reward) {
    state.rewardsActivity = { ...state.rewardsActivity, saving: true };
  },
  adminSaveRewardsActivitySuccess(state, reward) {
    state.rewardsActivity = { items: reward };
  },
  adminSaveRewardsActivityFailure(state, error) {
    state.rewardsActivity = {
      ...state.rewardsActivity,
      error: error,
      saving: false,
    };
  },
  adminGetRedemptionActivityRequest(state, redemptionActivity) {
    state.redeemedRewards = { ...state.redeemedRewards, loading: true };
  },
  adminGetRedemptionActivitySuccess(state, redemptionActivity) {
    state.redeemedRewards = { items: redemptionActivity.reverse() };
  },
  adminGetRedemptionActivityFailure(state, error) {
    state.redeemedRewards = {
      ...state.redeemedRewards,
      error: error,
      loading: false,
    };
  },
  adminRedeemRewardRequest(state, reward) {
    state.redeemedRewards = { ...state.redeemedRewards, saving: true };
  },
  adminRedeemRewardSuccess(state, reward) {
    // state.rewardsUser.current += reward.pointValue;
    state.redeemedRewards.items.push(reward);
    state.redeemedRewards = { ...state.redeemedRewards, saving: false };
  },
  adminRedeemRewardFailure(state, error) {
    state.redeemedRewards = {
      ...state.redeemedRewards,
      error: error,
      saving: false,
    };
  },
  adminTransferPointsRequest(state, reward) {
    state.rewardsActivity = { ...state.rewardsActivity, saving: true };
  },
  adminTransferPointsSuccess(state, transfer) {
    // state.rewardsUser.current += transfer.value;
    state.rewardsActivity = { ...state.rewardsActivity };
  },
  adminTransferPointsFailure(state, error) {
    state.rewardsActivity = {
      ...state.rewardsActivity,
      error: error,
      saving: false,
    };
  },
};

export const rewards = {
  namespaced: true,
  state,
  actions,
  mutations,
};
