import { userService } from "../_services";
import { charityService } from "../_services";
import { getField, updateField } from "vuex-map-fields";

const setState = () => {
  return {
    drawingResults: {},
    winners: {},
    yourCharityItems: {},
    allcharityitems: {},
    yourCasinoApp: {},
    allCasinoApps: {},
    charityDonation: 0,
    vrs: {
      loading: false,
      submitting: false,
      groupAOpen: false,
      groupBOpen: false,
    },
    readyToProcessPayment: false,
    paymentError: false,
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  clearResults({ commit }) {
    commit("clearResultsNow");
  },
  getCharityItems({ commit }) {
    commit("getCharityItemRequest");

    userService.getCharityItem().then(
      (charityItem) => commit("getCharityItemSuccess", charityItem),
      (error) => commit("getCharityItemFailure", error)
    );
  },
  getAllCharityItems({ commit }) {
    commit("getAllCharityRequest");

    userService.getAllCharityItems().then(
      (users) => commit("getAllCharitySuccess", users),
      (error) => commit("getAllCharityFailure", error)
    );
  },
  submitCharityItem({ commit, dispatch }, charityItem) {
    commit("submitCharityItemRequest", charityItem);

    userService.submitCharityItem(charityItem).then(
      (charItem) => {
        //if the preferredName parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        if (charityItem.name) commit("submitCharityItemSuccess", charItem);
        else commit("updateCharityItemSuccess", charItem);
        let msg =
          "Item " +
          (charityItem.name ? "submitted" : "updated") +
          " successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("submitCharityItemFailure", {
          charityItem,
          error: error.toString(),
        });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  runDrawing({ commit, dispatch }) {
    commit("runDrawingRequest");

    charityService.runDrawing().then(
      (casinoApp) => commit("runDrawingSuccess", casinoApp),
      (error) => {
        commit("runDrawingFailure", error);
      }
    );
  },
  getWinners({ commit, dispatch }) {
    commit("getWinnersRequest");

    charityService.getWinners().then(
      (casinoApp) => commit("getWinnersSuccess", casinoApp),
      (error) => {
        commit("getWinnersFailure", error);
      }
    );
  },
  confirmWinner({ commit, dispatch, state }, casinoApp) {
    commit("confirmWinnerRequest", casinoApp);

    charityService.confirmWinner(casinoApp).then(
      (volApp) => {
        //if the shirt size parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        commit("confirmWinnerSuccess", volApp);
        let msg = "Marked successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("confirmWinnerFailure", {
          casinoApp,
          error: error.toString(),
        });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  markNoShows({ commit, dispatch }, group) {
    commit("markNoShowsRequest", group);

    charityService.markNoShows(group).then(
      (casinoApp) => {
        let msg = "Marked No-shows";
        dispatch("alert/success", msg, {
          root: true,
        });
        commit("markNoShowsSuccess", casinoApp);
      },
      (error) => {
        commit("markNoShowsFailure", error);
      }
    );
  },

  getCasinoApp({ commit, dispatch }) {
    commit("getCasinoAppRequest");

    charityService.getCasinoApp().then(
      (casinoApp) => commit("getCasinoAppSuccess", casinoApp),
      (error) => {
        commit("getCasinoAppFailure", error);
      }
    );
  },
  deleteCasinoApp({ commit, dispatch }) {
    commit("deleteCasinoAppRequest");

    charityService.deleteCasinoApp().then(
      (casinoApp) => {
        let msg = "Entry deleted";
        dispatch("alert/success", msg, {
          root: true,
        });
        commit("deleteCasinoAppSuccess", casinoApp);
      },
      (error) => {
        commit("deleteCasinoAppFailure", error);
      }
    );
  },

  getAllCasinoApps({ commit, dispatch }) {
    commit("getAllCasinoRequest");

    charityService.getAllCasinoApps().then(
      (users) => commit("getAllCasinoSuccess", users),
      (error) => {
        commit("getAllCasinoFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  submitCasinoApp({ commit, dispatch, state }, casinoApp) {
    commit("submitCasinoAppRequest", casinoApp);

    charityService.submitCasinoApp(casinoApp).then(
      (volApp) => {
        //if the shirt size parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        commit("submitCasinoAppSuccess", volApp);
        let msg = "Submitted successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("submitCasinoAppFailure", {
          casinoApp,
          error: error.toString(),
        });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
};

const getters = {
  getField,
};

const mutations = {
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  updateItemName(state, value) {
    state.yourCharityItems.name = value;
  },
  updateItemDesc(state, value) {
    state.yourCharityItems.desc = value;
  },
  updateItemValue(state, value) {
    state.yourCharityItems.value = value;
  },
  updateItemBuyer(state, value) {
    state.yourCharityItems.buyer = value;
  },
  updateItemDonor(state, value) {
    state.yourCharityItems.donorName = value;
  },
  getCharityItemRequest(state) {
    state.yourCharityItems = { loading: true };
  },
  getCharityItemSuccess(state, charityItem) {
    state.yourCharityItems = { items: charityItem, loading: false };
  },
  getCharityItemFailure(state, error) {
    state.yourCharityItems = {};
  },
  getAllCharityRequest(state) {
    state.allcharityitems = { loading: true };
  },
  getAllCharitySuccess(state, charityItems) {
    state.allcharityitems = { items: charityItems, loading: false };
  },
  getAllCharityFailure(state, error) {
    state.allcharityitems = { ...state.allcharityitems, error, loading: false };
  },
  submitCharityItemRequest(state, charityItem) {
    state.yourCharityItems = {
      ...state.yourCharityItems,
      submitting: true,
      submitted: false,
    };
  },
  submitCharityItemSuccess(state, charityItem) {
    state.yourCharityItems = {
      items: charityItem,
      submitting: false,
      submitted: true,
    };
  },
  submitCharityItemFailure(state, error) {
    state.yourCharityItems = { ...state.yourCharityItems, error };
  },
  updateCharityItemSuccess(state, charityItem) {
    state.allcharityitems.items = state.allcharityitems.items.map((app) => {
      if (app.id == charityItem.id) {
        return { ...app, ...charityItem };
      }
      return app;
    });
  },
  updateField,
  runDrawingRequest(state) {
    state.vrs.submitting = true;
  },
  clearResultsNow(state) {
    state.drawingResults = {};
  },
  runDrawingSuccess(state, charityItem) {
    state.drawingResults = charityItem;
    state.vrs.submitting = false;
  },
  runDrawingFailure(state, error) {
    state.drawingResults = {};
    state.vrs.submitting = false;
  },
  getWinnersRequest(state) {
    state.vrs.submitting = true;
  },
  getWinnersSuccess(state, charityItem) {
    state.winners = charityItem;
    state.vrs.submitting = false;
  },
  getWinnersFailure(state, error) {
    state.winners = {};
    state.vrs.submitting = false;
  },
  markNoShowsRequest(state) {
    state.vrs.submitting = true;
  },
  markNoShowsSuccess(state, charityItem) {
    state.vrs.submitting = false;
  },
  markNoShowsFailure(state, error) {
    state.vrs.submitting = false;
  },
  updateCasinoGroup(state, value) {
    state.yourCasinoApp.group = value;
  },
  getCasinoAppRequest(state) {
    state.vrs.loading = true;
  },
  getCasinoAppSuccess(state, casinoApp) {
    state.yourCasinoApp = casinoApp.app ? casinoApp.app : {};
    state.vrs.groupAOpen = casinoApp.groupA;
    state.vrs.groupBOpen = casinoApp.groupB;
    state.vrs.loading = false;
  },
  getCasinoAppFailure(state, error) {
    state.vrs.loading = false;
  },
  deleteCasinoAppRequest(state) {
    state.vrs.submitting = true;
  },
  deleteCasinoAppSuccess(state, casinoApp) {
    state.yourCasinoApp = {};
    state.vrs.submitting = false;
  },
  deleteCasinoAppFailure(state, error) {
    state.vrs.submitting = false;
  },
  getAllCasinoRequest(state) {
    state.allCasinoApps = { loading: true };
  },
  getAllCasinoSuccess(state, CasinoApps) {
    state.allCasinoApps = {
      items: CasinoApps,
      loading: false,
    };
  },
  getAllCasinoFailure(state, error) {
    state.allCasinoApps = { ...state.allCasinoApps, error };
  },
  submitCasinoAppRequest(state, CasinoApp) {
    state.vrs.submitting = true;
  },
  submitCasinoAppSuccess(state, CasinoApp) {
    state.yourCasinoApp = CasinoApp.app;
    state.vrs.groupAOpen = CasinoApp.groupA;
    state.vrs.groupBOpen = CasinoApp.groupB;
    state.vrs.submitting = false;
  },
  updateCasinoAppSuccess(state, casinoApp) {
    state.allcasinoapps.items = state.allcasinoapps.items.map((app) => {
      if (app.attendeeID == casinoApp.app.attendeeID) {
        return { ...app, ...casinoApp.app };
      }
      return app;
    });
    state.vrs.groupAOpen = casinoApp.groupA;
    state.vrs.groupBOpen = casinoApp.groupB;
  },
  submitCasinoAppFailure(state, error) {
    state.vrs.submitting = false;
  },
  submitCharityDonationRequest(state, alpha) {},
  submitCharityDonationSuccess(state, alpha) {},
  submitCharityDonationFailure(state, error) {},
  confirmWinnerRequest(state, CasinoApp) {
    state.vrs.submitting = true;
  },
  confirmWinnerSuccess(state, winners) {
    state.winners = winners;
    state.vrs.submitting = false;
  },
  confirmWinnerFailure(state, error) {
    state.vrs.submitting = false;
  },
};

export const charity = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
