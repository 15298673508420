import { userService } from "../_services";

const setState = () => {
  return {
    all: {},
    allPermissionForms: {},
    order: {},
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  getAll({ commit }) {
    commit("getAllRequest");

    userService.getAll().then(
      (users) => commit("getAllSuccess", users),
      (error) => commit("getAllFailure", error)
    );
  },

  delete({ commit }, id) {
    commit("deleteRequest", id);

    userService.delete(id).then(
      (user) => commit("deleteSuccess", id),
      (error) => commit("deleteFailure", { id, error: error.toString() })
    );
  },

  getOrder({ commit, dispatch }) {
    commit("getOrderRequest");

    userService.getOrder().then(
      (family) => commit("getOrderSuccess", family),
      (error) => {
        commit("getOrderFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getAllPermForms({ commit, dispatch }) {
    commit("getAllPFRequest");

    userService.getAllPermForms().then(
      (permForms) => commit("getAllPFSuccess", permForms),
      (error) => {
        commit("getAllPFFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  updatePermForm({ commit, dispatch }, person) {
    commit("updatePermFormRequest");
    userService.updatePermForm(person).then(
      (pForm) => {
        commit("updatePermFormSuccess", pForm);
        dispatch("alert/success", "Form updated successfully.", { root: true });
      },
      (error) => {
        commit("updatePermFormFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  updateDependent({ commit, dispatch }, outgoingDependent) {
    commit("submitDependentRequest", outgoingDependent);

    userService.updateDependent(outgoingDependent).then(
      (returnedDep) => {
        commit("submitDependentSuccess", { returnedDep, outgoingDependent });
        let msg =
          outgoingDependent.familyID == null
            ? "Dependent unlinked successfully."
            : "Dependent added successfully.";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("submitDependentFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
};

const mutations = {
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  getAllRequest(state) {
    state.all = { loading: true };
  },
  getAllSuccess(state, users) {
    state.all = { items: users };
  },
  getAllFailure(state, error) {
    state.all = { error };
  },
  getAllPFRequest(state) {
    state.allPermissionForms = { loading: true };
  },
  getAllPFSuccess(state, permForms) {
    state.allPermissionForms = { items: permForms };
  },
  getAllPFFailure(state, error) {
    state.allPermissionForms = { error };
  },
  updatePermFormRequest(state) {
    state.allPermissionForms = { ...state.allPermissionForms };
  },
  updatePermFormSuccess(state, PF) {
    state.allPermissionForms.items = state.allPermissionForms.items.map(
      (pForm) => {
        if (
          pForm.attendeeID == PF.attendeeID &&
          pForm.fileName == PF.fileName
        ) {
          return { ...pForm, ...PF };
        }
        return pForm;
      }
    );
  },
  updatePermFormFailure(state, error) {
    state.allPermissionForms = { ...state.allPermissionForms, error };
  },
  getOrderRequest(state) {
    state.order = { ...state.order, loading: true };
  },
  getOrderSuccess(state, family) {
    state.order = { items: family };
  },
  getOrderFailure(state, error) {
    state.order = { ...state.order, error };
  },
  deleteRequest(state, id) {
    // add 'deleting:true' property to user being deleted
    state.all.items = state.all.items.map((user) =>
      user.id === id ? { ...user, deleting: true } : user
    );
  },
  deleteSuccess(state, id) {
    // remove deleted user from state
    state.all.items = state.all.items.filter((user) => user.id !== id);
  },
  deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.all.items = state.items.map((user) => {
      if (user.id === id) {
        // make copy of user without 'deleting:true' property
        const { deleting, ...userCopy } = user;
        // return copy of user with 'deleteError:[error]' property
        return { ...userCopy, deleteError: error };
      }

      return user;
    });
  },
  submitDependentRequest(state, dependent) {
    state.order = { ...state.order, loading: true };
  },
  submitDependentSuccess(state, family) {
    if (family.outgoingDependent.familyID == null) {
      state.order.items = state.order.items.filter(
        (user) => user.attendeeID !== family.outgoingDependent.attendeeID
      );
    } else {
      state.order.items.push(family.returnedDep);
    }
    state.order = { ...state.order, loading: false };
  },
  submitDependentFailure(state, error) {
    state.order = { ...state.order, error, loading: false };
  },
};

export const users = {
  namespaced: true,
  state,
  actions,
  mutations,
};
