import store from "../_store/index";

export function authHeader() {
  const cName = "loginToken";
  const hName = "helloToken";
  const name = cName + "=";
  const nameh = hName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let cs, hl;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) cs = val.substring(name.length);
    if (val.indexOf(nameh) === 0) hl = val.substring(name.length);
  });
  if (cs && hl) {
    return { "x-login-token": cs, "x-hello-token": hl };
  } else if (cs) return { "x-login-token": cs };
  else if (hl) return { "x-hello-token": hl };
  else return {};
}
