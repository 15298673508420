<template id="bg">
  <div id="app">
    <div class="lighten" id="bg"></div>
    <transition name="fade" mode="out-in">
      <div v-if="alert.message" :class="`alert mainAlert ${alert.type}`">
        {{ alert.message }}
        <div id="backfill"></div>
      </div>
    </transition>
    <Sidebar v-if="!isLogin && account.user" />
    <router-view
      v-slot="{ Component }"
      v-if="
        isLogin ||
        (!isLogin && account.user) ||
        this.$route.name === 'Register' ||
        (this.$route.name && this.$route.name.includes('Shoes'))
      "
    >
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "app",
  components: {
    Sidebar,
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      account: (state) => state.account,
      yourReg: (state) => state.register.yourRegistration,
    }),
    isLogin() {
      return this.$route.name === "Login" || this.$route.name === "Privacy";
    },
    acctStatus() {
      return this.account.status;
    },
  },
  created() {
    const loginToken = document.cookie.match(
      RegExp("(?:^|;\\s*)" + "loginToken" + "=([^;]*)")
    );
    if (loginToken) {
      this.reauth();
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      reauth: "account/reauth",
      pullExisting: "register/pullExistingRegistration",
    }),
  },
  watch: {
    "alert.message"() {
      setTimeout(() => {
        this.clearAlert();
      }, 5000);
    },
    $route(to, from) {
      this.clearAlert();
    },
    acctStatus(value) {
      if (value.loggedIn) {
        this.pullExisting();
      }
    },
  },
};
</script>

<style lang="scss">
@import "bootstrap";
#app {
  color: #11224d;
  display: flex;
  font-family: Arial, sans-serif;
  height: 100vh;
  moz-osx-font-smoothing: grayscale;
  webkit-font-smoothing: antialiased;
  width: 100%;
}
#backfill {
  animation: growin 5s linear forwards;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  left: 0;
  left: -4%;
  position: absolute;
  top: 0;
  transform: skew(-20deg);
  width: 0%;
}
#bg {
  background-image: url("./assets/mascotpattern.png");
  background-repeat: repeat;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: -20;
}
.accordion-body p {
  color: #11224d;
}
label {
  color: #11224d;
}
.bodytext {
  color: #11224d;
}
.btn-o {
  background-color: #f98125 !important;
  border-color: #f98125 !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.headfont {
  font-family: "Generica Bold" !important;
  text-align: center;
}
.homemain {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 10px;
  padding-top: 25px;
  width: 100%;
}
.homemain > main {
  margin: auto;
}
.lighten {
  filter: opacity(10%);
}
.loading-dual-ring {
  display: inline-block;
  height: 80px;
  width: 80px;
}
.loading-dual-ring:after {
  animation: loading-dual-ring 1.2s linear infinite;
  border: 6px solid var(--bs-blue);
  border-color: var(--bs-blue) transparent var(--bs-blue) transparent;
  border-radius: 50%;
  content: " ";
  display: block;
  height: 64px;
  margin: 8px;
  width: 64px;
}
.mainAlert {
  animation: jiggle 2s ease-in;
  bottom: 0;
  margin: 30px !important;
  overflow: hidden;
  position: absolute !important;
  right: 0;
  z-index: 2000;
}
.modal {
  background-color: rgba(17, 34, 77, 0.8);
}
.neckfont {
  font-family: "Generica" !important;
}
.sevenFiveHundred {
  width: 75%;
}
.stdBorder {
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid #11224d;
  margin: 15px 10%;
  padding: 25px;
}
.stdBorder .stdBorder {
  border: 2px solid #d4d4d4;
}
.dp__main,
.dp__input {
  font-family: Arial, sans-serif !important;
  border-color: rgb(206, 212, 218) !important;
  border-radius: 0.375rem !important;
}
@font-face {
  font-family: "Generica Bold";
  font-display: auto;
  src: local("Generica Bold"),
    url("../public/Generica Bold.otf") format("opentype");
}
@font-face {
  font-family: "Generica";
  font-display: auto;
  src: local("Generica"), url("../public/Generica.otf") format("opentype");
}
@include media-breakpoint-down(lg) {
  #app {
    flex-direction: column;
  }
}
@include media-breakpoint-down(sm) {
  h2 {
    text-align: center;
  }
  .sevenFiveHundred {
    width: 100%;
  }
  .stdBorder {
    margin: 15px 9px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .stdBorder .stdBorder {
    margin-left: 0;
    margin-right: 0;
  }
}
@keyframes growin {
  from {
    width: 0%;
  }
  to {
    width: 116%;
  }
}
@keyframes jiggle {
  45%,
  65% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  55% {
    transform: scale(0.9, 1.1) translate(0, -5px);
  }
  60% {
    transform: scale(1, 1) translate(0, -5px);
  }
}
@keyframes loading-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  .mainAlert {
    animation: none;
    margin: 0px !important;
    width: 100%;
  }
}
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
button {
  font-family: "Generica Bold" !important;
}
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
html {
  height: -webkit-fill-available;
}
</style>
