import { userService } from "../_services";

const setState = () => {
  return {
    yourDJApp: {},
    alldjapps: {},
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  getDJApp({ commit, dispatch }, id) {
    commit("getDJAppRequest");

    userService.getDJApp(id).then(
      (djApp) => commit("getDJAppSuccess", djApp),
      (error) => {
        commit("getDJAppFailure", error);
      }
    );
  },
  getAllDJApps({ commit, dispatch }) {
    commit("getAllRequest");

    userService.getAllDJApps().then(
      (users) => commit("getAllSuccess", users),
      (error) => {
        commit("getAllFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  submitDJApp({ commit, dispatch }, djApp) {
    commit("submitDJAppRequest", djApp);

    userService.submitDJApp(djApp).then(
      (volApp) => {
        //if the djName size parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        if (djApp.djName) commit("submitDJAppSuccess", volApp);
        else commit("updateDJAppSuccess", volApp);
        let msg =
          "Application " +
          (djApp.djName ? "submitted" : "updated") +
          " successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("submitDJAppFailure", {
          djApp,
          error: error.toString(),
        });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
};

const mutations = {
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  updateDJName(state, value) {
    state.yourDJApp.djName = value;
  },
  updateGenre(state, value) {
    state.yourDJApp.genre = value;
  },
  updateAvailability(state, value) {
    state.yourDJApp.availability = value;
  },
  updateEquipment(state, value) {
    state.yourDJApp.equipment = value;
  },
  updateDemo(state, value) {
    state.yourDJApp.demo = value;
  },
  updatePriorSkills(state, value) {
    state.yourDJApp.priorSkills = value;
  },
  getDJAppRequest(state) {
    state.yourDJApp = { loading: true };
  },
  getDJAppSuccess(state, djApp) {
    if (djApp) {
      state.yourDJApp = djApp;
    } else state.yourDJApp = {};
  },
  getDJAppFailure(state, error) {
    state.yourDJApp = {};
  },
  getAllRequest(state) {
    state.alldjapps = { loading: true };
  },
  getAllSuccess(state, djApps) {
    state.alldjapps = { items: djApps };
  },
  getAllFailure(state, error) {
    state.alldjapps = { ...state.alldjapps, error };
  },
  submitDJAppRequest(state, djApp) {
    state.yourDJApp = { ...state.yourDJApp, submitting: true };
  },
  submitDJAppSuccess(state, djApp) {
    state.yourDJApp = djApp;
  },
  updateDJAppSuccess(state, djApp) {
    state.alldjapps.items = state.alldjapps.items.map((app) => {
      if (app.attendeeID == djApp.attendeeID) {
        return { ...app, ...djApp };
      }
      return app;
    });
  },
  submitDJAppFailure(state, error) {
    state.yourDJApp = { ...state.yourDJApp, error };
  },
};

export const dj = {
  namespaced: true,
  state,
  actions,
  mutations,
};
