import { userService } from "../_services";

const setState = () => {
  return {
    all: [],
    file: {},
    orderFiles: {},
    userFiles: {},
    filesAreUploading: false,
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  uploadFile({ commit, dispatch }, file) {
    commit("uploadFileRequest", file);

    userService.uploadFile(file).then(
      (volApp) => {
        commit("uploadFileSuccess", volApp);
        if (file.fileType < 3)
          dispatch("alert/success", "File uploaded successfully!", {
            root: true,
          });
      },
      (error) => {
        commit("uploadFileFailure", { file, error: error.toString() });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  uploadUserFile({ commit, dispatch }, file) {
    commit("uploadUserFileRequest", file);

    userService.uploadFile(file).then(
      (volApp) => {
        commit("uploadUserFileSuccess", volApp);
        if (file.fileType < 3)
          dispatch("alert/success", "File uploaded successfully!", {
            root: true,
          });
      },
      (error) => {
        commit("uploadUserFileFailure", { file, error: error.toString() });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  setUploadedToFalse({ commit }) {
    commit("uploadedToFalse");
  },
  /* Staged for deletion
  getSingleFile({ commit }, person) {
    commit("getSingleFileRequest");
    userService.getSingleFile(person).then(
      (files) => commit("getSingleFileSuccess", files),
      (error) => commit("getSingleFileFailure", error)
    );
  },*/
  removeFile({ commit }, person) {
    commit("removeFile", person);
  },
  getOrderFiles({ commit }) {
    commit("getOrderFilesRequest");
    userService.getOrderFiles().then(
      (files) => commit("getOrderFilesSuccess", files),
      (error) => commit("getOrderFilesFailure", error)
    );
  },
  getUserFiles({ commit }) {
    commit("getUserFilesRequest");
    userService.getOrderFiles().then(
      (files) => commit("getUserFilesSuccess", files),
      (error) => commit("getUserFilesFailure", error)
    );
  },
  downloadFile({ commit, dispatch }, file) {
    commit("downloadFileRequest");
    userService.downloadFile(file).then(
      (files) => commit("downloadFileSuccess", files),
      (error) => {
        commit("downloadFileFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
};

const mutations = {
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  uploadedToFalse(state) {
    state.userFiles = { ...state.userFiles, uploaded: false };
  },
  uploadFileRequest(state, file) {
    state.filesAreUploading = true;
    state.file = { ...state.file, loading: true };
    state.orderFiles = { ...state.orderFiles, loading: true };
  },
  uploadFileSuccess(state, files) {
    state.orderFiles = { ...state.orderFiles, files, loading: false };
    state.filesAreUploading = false;
    state.file = {};
  },
  uploadFileFailure(state, error) {
    state.orderFiles = { ...state.orderFiles, loading: false };
    state.filesAreUploading = false;
    state.file = { error };
  },
  uploadUserFileRequest(state, file) {
    state.userFiles = { ...state.userFiles, loading: true };
    state.filesAreUploading = true;
    state.file = { ...state.file, loading: true };
  },
  uploadUserFileSuccess(state, files) {
    state.userFiles = {
      ...state.userFiles,
      files,
      loading: false,
      uploaded: true,
    };
    state.filesAreUploading = false;
    state.file = {};
  },
  uploadUserFileFailure(state, error) {
    state.userFiles = { ...state.userFiles, loading: false };
    state.filesAreUploading = false;
    state.file = { error };
  },
  /*
  getSingleFileRequest(state) {
    state.order = { ...state.file, loading: true };
  },
  getSingleFileSuccess(state, files) {
    state.all.push(files);
  },
  getSingleFileFailure(state, error) {
    state.all = { error };
  },
  */
  getOrderFilesRequest(state) {
    state.orderFiles = { ...state.orderFiles, loading: true };
  },
  getOrderFilesSuccess(state, files) {
    state.orderFiles = { ...state.orderFiles, files, loading: false };
  },
  getOrderFilesFailure(state, error) {
    state.orderFiles = { error };
  },
  getUserFilesRequest(state) {
    state.userFiles = { ...state.userFiles, loading: true };
  },
  getUserFilesSuccess(state, files) {
    state.userFiles = { ...state.userFiles, files, loading: false };
  },
  getUserFilesFailure(state, error) {
    state.userFiles = { error };
  },
  downloadFileRequest(state) {},
  downloadFileSuccess(state) {},
  downloadFileFailure(state, error) {
    state.all = { error };
  },
  removeFile(state, person) {
    state.orderFiles.files = state.orderFiles.files.filter(
      (file) =>
        file.attendeeID !== person.attendeeID &&
        file.byAttendeeID !== person.byAttendeeID
    );
  },
};

export const files = {
  namespaced: true,
  state,
  actions,
  mutations,
};
