<template>
  <div
    id="navContainer"
    class="min-vh-100 d-none d-lg-flex flex-column flex-shrink-0 p-3 text-white bg-dark"
  >
    <a
      href="/"
      class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
    >
      <figure class="w-75 pt-2 m-0">
        <img
          class="mw-100"
          src="../assets/MyAWU-White-Combined-Logo.svg"
          alt="Anthro Weekend Utah Logo"
        />
      </figure>
    </a>
    <SidebarInner bottomAvatar />
  </div>
  <div id="mobileNavContainer" class="d-lg-none">
    <nav class="navbar navbar-dark bg-dark" style="z-index: 1046">
      <div class="container-fluid d-flex justify-content-between">
        <a
          href="/"
          class="d-flex align-items-center mb-0 me-auto ps-2 text-white text-decoration-none navbar-brand"
        >
          <figure class="m-0">
            <img
              style="max-height: 45px"
              src="../assets/MyAWU-White-Combined-Logo.svg"
              alt="Anthro Weekend Utah Logo"
            />
          </figure>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#navbarToggleOptions"
          aria-controls="navbarToggleOptions"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"> </span>
        </button>
      </div>
    </nav>
    <div
      class="offcanvas offcanvas-top h-100"
      id="navbarToggleOptions"
      tabindex="-1"
    >
      <div
        class="bg-dark offcanvas-body d-flex flex-column"
        style="padding-top: 50px"
      >
        <hr style="background-color: white" />
        <span class="text-white pb-2" style="margin-left: auto">
          <strong class="text-truncate pe-2" v-if="account.user">{{
            account.user.username ? account.user.username : "New User"
          }}</strong>
          <img
            :src="
              getAvatar(
                account.user && account.user.avatar
                  ? account.user.avatar
                  : 'Wolf.svg'
              )
            "
            alt=""
            width="50"
            height="50"
            class="rounded-circle"
          />
        </span>
        <SidebarInner />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import SidebarInner from "./SidebarInner.vue";

export default {
  name: "Sidebar",
  components: {
    SidebarInner,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  created() {},
  methods: {
    getAvatar(src) {
      return require("../assets/avatars/" + src);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "bootstrap";

#navContainer {
  width: 280px;
}

@include media-breakpoint-down(lg) {
  #navContainer {
    //width: 100%;
  }
}
</style>
