import { userService } from "../_services";

const setState = () => {
  return {
    yourMusicFestivalApp: {},
    allMusicFestivalApps: {},
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  getMusicFestivalApp({ commit, dispatch }, id) {
    commit("getMusicFestivalAppRequest");

    userService.getMusicFestivalApp(id).then(
      (musicFestivalApp) =>
        commit("getMusicFestivalAppSuccess", musicFestivalApp),
      (error) => {
        commit("getMusicFestivalAppFailure", error);
      }
    );
  },
  getAllMusicFestivalApps({ commit, dispatch }) {
    commit("getAllRequest");

    userService.getAllMusicFestivalApps().then(
      (users) => commit("getAllSuccess", users),
      (error) => {
        commit("getAllFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  submitMusicFestivalApp({ commit, dispatch }, musicFestivalApp) {
    commit("submitMusicFestivalAppRequest", musicFestivalApp);

    userService.submitMusicFestivalApp(musicFestivalApp).then(
      (festivalApp) => {
        //if the danceCompName size parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        if (musicFestivalApp.groupName)
          commit("submitMusicFestivalAppSuccess", festivalApp);
        else commit("updateMusicFestivalAppSuccess", festivalApp);
        let msg =
          "Application " +
          (musicFestivalApp.groupName ? "submitted" : "updated") +
          " successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("submitMusicFestivalAppFailure", {
          musicFestivalApp,
          error: error.toString(),
        });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
};

const mutations = {
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  updateGroupName(state, value) {
    state.yourMusicFestivalApp.groupName = value;
  },
  updatePerformers(state, value) {
    state.yourMusicFestivalApp.performers = value;
  },
  updateGenre(state, value) {
    state.yourMusicFestivalApp.genre = value;
  },
  updateLogo(state, value) {
    state.yourMusicFestivalApp.logo = value;
  },
  updateDemo(state, value) {
    state.yourMusicFestivalApp.demo = value;
  },
  updateInputs(state, value) {
    state.yourMusicFestivalApp.inputs = value;
  },
  getMusicFestivalAppRequest(state) {
    state.yourMusicFestivalApp = { loading: true };
  },
  getMusicFestivalAppSuccess(state, musicFestivalApp) {
    if (musicFestivalApp) {
      state.yourMusicFestivalApp = musicFestivalApp;
    } else state.yourMusicFestivalApp = {};
  },
  getMusicFestivalAppFailure(state, error) {
    state.yourMusicFestivalApp = {};
  },
  getAllRequest(state) {
    state.allMusicFestivalApps = { loading: true };
  },
  getAllSuccess(state, musicFestivalApps) {
    state.allMusicFestivalApps = { items: musicFestivalApps };
  },
  getAllFailure(state, error) {
    state.allMusicFestivalApps = { ...state.allMusicFestivalApps, error };
  },
  submitMusicFestivalAppRequest(state, musicFestivalApp) {
    state.yourMusicFestivalApp = {
      ...state.yourMusicFestivalApp,
      submitting: true,
    };
  },
  submitMusicFestivalAppSuccess(state, musicFestivalApp) {
    state.yourMusicFestivalApp = musicFestivalApp;
  },
  updateMusicFestivalAppSuccess(state, musicFestivalApp) {
    state.allMusicFestivalApps.items = state.allMusicFestivalApps.items.map(
      (app) => {
        if (app.attendeeID == musicFestivalApp.attendeeID) {
          return { ...app, ...musicFestivalApp };
        }
        return app;
      }
    );
  },
  submitMusicFestivalAppFailure(state, error) {
    state.yourMusicFestivalApp = { ...state.yourMusicFestivalApp, error };
  },
};

export const musicfestival = {
  namespaced: true,
  state,
  actions,
  mutations,
};
