import config from "config";
import { authHeader } from "../_helpers";
export const userService = {
  hello,
  login,
  reauth,
  logout,
  register,
  recover,
  getById,
  getAlpha,
  getAllAlphas,
  getShoe,
  submitShoe,
  submitDinner,
  getVolunteerApp,
  getAllVolunteerApps,
  getPendingVolShifts,
  saveVolShift,
  submitVolunteerApp,
  getDanceCompApp,
  getAllDanceCompApps,
  submitDanceCompApp,
  getMusicFestivalApp,
  getAllMusicFestivalApps,
  submitMusicFestivalApp,
  getDJApp,
  getAllDJApps,
  submitDJApp,
  getOrder,
  getRewards,
  adminGetRewards,
  saveReward,
  adminSaveReward,
  getRewardsUser,
  adminGetRewardsUser,
  saveRewardsUser,
  adminSaveRewardsUser,
  redeemReward,
  adminRedeemReward,
  getRedemptionActivity,
  adminGetRedemptionActivity,
  transferPoints,
  adminTransferPoints,
  uploadFile,
  getSingleFile,
  getOrderFiles,
  getUserFiles,
  getAllPermForms,
  updatePermForm,
  downloadFile,
  updateDependent,
  getPanelApp,
  deletePanelApp,
  getAllPanelApps,
  submitPanelApp,
  getCohost,
  getCharityItem,
  getAllCharityItems,
  submitCharityItem,
  update,
  deleteAccount,
};

function hello() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/hello`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function reauth(token) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ token }),
  };

  return fetch(`${config.apiUrl}/users/current`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      //re-login was successful
      return user;
    });
}

function logout() {
  // remove cookies to log user out
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/logout`, requestOptions).then(
    handleResponse
  );
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/users/register`, requestOptions).then(
    handleResponse
  );
}

function recover(user) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/users/recover`, requestOptions).then(
    handleResponse
  );
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), id: id },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function getAlpha(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), id: id },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/alpha`, requestOptions).then(
    handleResponse
  );
}

function getShoe(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), id: id },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/shoe`, requestOptions).then(
    handleResponse
  );
}

function submitShoe(shoeSize) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(shoeSize),
  };

  return fetch(`${config.apiUrl}/users/shoe`, requestOptions).then(
    handleResponse
  );
}

function getAllAlphas() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/alpha/all`, requestOptions).then(
    handleResponse
  );
}

function submitDinner(dinner) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(dinner),
  };

  return fetch(`${config.apiUrl}/users/alpha`, requestOptions).then(
    handleResponse
  );
}

function getVolunteerApp(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), id: id },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/volunteerApp`, requestOptions).then(
    handleResponse
  );
}

function getAllVolunteerApps() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/volunteerApps/all`, requestOptions).then(
    handleResponse
  );
}

function submitVolunteerApp(volunteerApp) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(volunteerApp),
  };
  return fetch(`${config.apiUrl}/users/volunteerApp`, requestOptions)
    .then(handleResponse)
    .then((volApp) => {
      return volApp;
    });
}

function getPendingVolShifts() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/volClock/pending`, requestOptions).then(
    handleResponse
  );
}

function saveVolShift(volShift) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(volShift),
  };
  return fetch(`${config.apiUrl}/users/volClock/pending`, requestOptions)
    .then(handleResponse)
    .then((vs) => {
      return vs;
    });
}

function getDJApp(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), id: id },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/djApp`, requestOptions).then(
    handleResponse
  );
}

function getAllDJApps() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/djApps/all`, requestOptions).then(
    handleResponse
  );
}

function submitDJApp(djApp) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(djApp),
  };
  return fetch(`${config.apiUrl}/users/djApp`, requestOptions)
    .then(handleResponse)
    .then((djApp) => {
      return djApp;
    });
}

function getDanceCompApp(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), id: id },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/danceCompApp`, requestOptions).then(
    handleResponse
  );
}

function getAllDanceCompApps() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/danceCompApps/all`, requestOptions).then(
    handleResponse
  );
}

function submitDanceCompApp(danceCompApp) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(danceCompApp),
  };
  return fetch(`${config.apiUrl}/users/danceCompApp`, requestOptions)
    .then(handleResponse)
    .then((danceCompApp) => {
      return danceCompApp;
    });
}

function getMusicFestivalApp(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), id: id },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/musicFestivalApp`, requestOptions).then(
    handleResponse
  );
}

function getAllMusicFestivalApps() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(
    `${config.apiUrl}/users/musicFestivalApps/all`,
    requestOptions
  ).then(handleResponse);
}

function submitMusicFestivalApp(musicFestivalApp) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(musicFestivalApp),
  };
  return fetch(`${config.apiUrl}/users/musicFestivalApp`, requestOptions)
    .then(handleResponse)
    .then((musicFestivalApp) => {
      return musicFestivalApp;
    });
}

function getOrder() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/order`, requestOptions).then(
    handleResponse
  );
}

function getRewards() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/rewards/activity`, requestOptions).then(
    handleResponse
  );
}

function adminGetRewards() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/rewards/adminactivity`, requestOptions).then(
    handleResponse
  );
}

function saveReward(reward) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(reward),
  };
  return fetch(`${config.apiUrl}/rewards/activity`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function adminSaveReward(reward) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(reward),
  };
  return fetch(`${config.apiUrl}/rewards/adminactivity`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function getRewardsUser() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/rewards/user`, requestOptions).then(
    handleResponse
  );
}

function adminGetRewardsUser() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/rewards/adminuser`, requestOptions).then(
    handleResponse
  );
}

function saveRewardsUser(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(user),
  };
  return fetch(`${config.apiUrl}/rewards/user`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function adminSaveRewardsUser(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(user),
  };
  return fetch(`${config.apiUrl}/rewards/adminuser`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function getRedemptionActivity() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/rewards/redemption`, requestOptions).then(
    handleResponse
  );
}

function adminGetRedemptionActivity() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/rewards/adminredemption`, requestOptions).then(
    handleResponse
  );
}

function redeemReward(reward) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(reward),
  };
  return fetch(`${config.apiUrl}/rewards/redemption`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function adminRedeemReward(reward) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(reward),
  };
  return fetch(`${config.apiUrl}/rewards/adminredemption`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function transferPoints(transaction) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(transaction),
  };
  return fetch(`${config.apiUrl}/rewards/transfer`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function adminTransferPoints(transaction) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(transaction),
  };
  return fetch(`${config.apiUrl}/rewards/admintransfer`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function uploadFile(file) {
  const fd = new FormData();

  fd.append("attendeeID", file.attendeeID);
  fd.append("fileType", file.fileType);
  fd.append("familyID", file.familyID);
  fd.append("file", file.file);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    credentials: "include",
    body: fd,
  };
  return fetch(`${config.apiUrl}/files/upload`, requestOptions)
    .then(handleResponse)
    .then((uploaded) => {
      return uploaded;
    });
}

function getSingleFile(params) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), id: params.id, ft: params.ft },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/files/single`, requestOptions)
    .then(handleResponse)
    .then((file) => {
      return file;
    });
}

function getOrderFiles() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/files/order`, requestOptions).then(
    handleResponse
  );
}

function getUserFiles() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/files/user`, requestOptions).then(
    handleResponse
  );
}

function getAllPermForms() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/order/all`, requestOptions).then(
    handleResponse
  );
}

function updatePermForm(person) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(person),
  };
  return fetch(`${config.apiUrl}/files/update`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function downloadFile(params) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      id: params.id,
      file: params.file,
      friendly: params.friendly,
    },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/files/dl`, requestOptions)
    .then(handleNonTextResponse)
    .then((response) => response.blob())
    .then(function (fileBlob) {
      const fileURL = URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = fileURL;
      //link.download = params.friendly;
      link.target = "_blank";
      link.click();
      link.remove();
    });
}

function updateDependent(dependent) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(dependent),
  };
  return fetch(`${config.apiUrl}/users/order`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function getPanelApp() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/panelApp`, requestOptions).then(
    handleResponse
  );
}

function deletePanelApp(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(id),
  };
  return fetch(`${config.apiUrl}/users/panelApp`, requestOptions).then(
    handleResponse
  );
}

function getAllPanelApps() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/panelApps/all`, requestOptions).then(
    handleResponse
  );
}

function submitPanelApp(panelApp) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(panelApp),
  };

  return fetch(`${config.apiUrl}/users/panelApp`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function getCohost(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };

  return fetch(`${config.apiUrl}/users/panelApp/cohost`, requestOptions).then(
    handleResponse
  );
}

function getCharityItem() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/charityItem`, requestOptions).then(
    handleResponse
  );
}

function getAllCharityItems() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/charityItems/all`, requestOptions).then(
    handleResponse
  );
}

function submitCharityItem(charityItem) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(charityItem),
  };

  return fetch(`${config.apiUrl}/users/charityItem`, requestOptions)
    .then(handleResponse)
    .then((dep) => {
      return dep;
    });
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/users/update`, requestOptions).then(
    handleResponse
  );
}

function deleteAccount(pass) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(pass),
  };

  return fetch(`${config.apiUrl}/users/delete`, requestOptions)
    .then(handleResponse)
    .then((resp) => {
      logout();
      location.reload(true);
    });
}

function handleNonTextResponse(response) {
  if (!response.ok) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      const error = (data && data.message) || response.statusText;
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      //console.clear();
      return Promise.reject(error);
    });
  }
  return response;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      //console.clear();
      return Promise.reject(error);
    }

    return data;
  });
}
