import { userService } from "../_services";

const setState = () => {
  return {
    yourVolunteerApp: {},
    allvolunteerapps: {},
    allpendingshifts: {},
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  getVolunteerApp({ commit, dispatch }, id) {
    commit("getVolunteerAppRequest");

    userService.getVolunteerApp(id).then(
      (volunteerApp) => commit("getVolunteerAppSuccess", volunteerApp),
      (error) => {
        commit("getVolunteerAppFailure", error);
      }
    );
  },
  getAllVolunteerApps({ commit, dispatch }) {
    commit("getAllRequest");

    userService.getAllVolunteerApps().then(
      (users) => commit("getAllSuccess", users),
      (error) => {
        commit("getAllFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  submitVolunteerApp({ commit, dispatch }, volunteerApp) {
    commit("submitVolunteerAppRequest", volunteerApp);

    userService.submitVolunteerApp(volunteerApp).then(
      (volApp) => {
        //if the shirt size parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        if (volunteerApp.tShirtSize)
          commit("submitVolunteerAppSuccess", volApp);
        else commit("updateVolunteerAppSuccess", volApp);
        let msg =
          "Application " +
          (volunteerApp.tShirtSize ? "submitted" : "updated") +
          " successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("submitVolunteerAppFailure", {
          volunteerApp,
          error: error.toString(),
        });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  getPendingVolShifts({ commit, dispatch }) {
    commit("getPendingRequest");

    userService.getPendingVolShifts().then(
      (users) => commit("getPendingSuccess", users),
      (error) => {
        commit("getPendingFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  saveVolShift({ commit, dispatch }, volunteerShift) {
    commit("saveVolShiftRequest", volunteerShift);

    userService.saveVolShift(volunteerShift).then(
      (volShift) => {
        //if the shirt size parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        commit("saveVolShiftSuccess", volShift);
        let msg = "Shift updated successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("saveVolShiftFailure", {
          volunteerShift,
          error: error.toString(),
        });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
};

const mutations = {
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  updateTShirtSize(state, value) {
    state.yourVolunteerApp.tShirtSize = value;
  },
  updateMaxHours(state, value) {
    state.yourVolunteerApp.maxHours = value;
  },
  updateAvailability(state, value) {
    state.yourVolunteerApp.availability = value;
  },
  updateAWUExperience(state, value) {
    state.yourVolunteerApp.awuExperience = value;
  },
  updateMiscSkills(state, value) {
    state.yourVolunteerApp.miscSkills = value;
  },
  updateSizing(state, value) {
    state.yourVolunteerApp.sizing = value;
  },
  updateSecurity(state, value) {
    state.yourVolunteerApp.security = value;
  },
  updatePriorSkills(state, value) {
    state.yourVolunteerApp.priorSkills = value;
  },
  getVolunteerAppRequest(state) {
    state.yourVolunteerApp = { loading: true };
  },
  getVolunteerAppSuccess(state, volunteerApp) {
    if (volunteerApp) {
      state.yourVolunteerApp = volunteerApp;
    } else state.yourVolunteerApp = {};
  },
  getVolunteerAppFailure(state, error) {
    state.yourVolunteerApp = {};
  },
  getAllRequest(state) {
    state.allvolunteerapps = { loading: true };
  },
  getAllSuccess(state, volunteerApps) {
    state.allvolunteerapps = { items: volunteerApps };
  },
  getAllFailure(state, error) {
    state.allvolunteerapps = { ...state.allvolunteerapps, error };
  },
  submitVolunteerAppRequest(state, volunteerApp) {
    state.yourVolunteerApp = { ...state.yourVolunteerApp, submitting: true };
  },
  submitVolunteerAppSuccess(state, volunteerApp) {
    state.yourVolunteerApp = volunteerApp;
  },
  updateVolunteerAppSuccess(state, volunteerApp) {
    state.allvolunteerapps.items = state.allvolunteerapps.items.map((app) => {
      if (app.attendeeID == volunteerApp.attendeeID) {
        return { ...app, ...volunteerApp };
      }
      return app;
    });
  },
  submitVolunteerAppFailure(state, error) {
    state.yourVolunteerApp = {
      ...state.yourVolunteerApp,
      submitting: false,
      error,
    };
  },
  getPendingRequest(state) {
    state.allpendingshifts = { loading: true };
  },
  getPendingSuccess(state, pendingShifts) {
    state.allpendingshifts = { items: pendingShifts };
  },
  getPendingFailure(state, error) {
    state.allpendingshifts = { ...state.allpendingshifts, error };
  },
  saveVolShiftRequest(state, volShift) {
    state.allpendingshifts = { ...state.allpendingshifts, submitting: true };
  },
  saveVolShiftSuccess(state, volShift) {
    state.allpendingshifts.items = state.allpendingshifts.items.map((shift) => {
      if (shift.id == volShift.id) {
        return { ...shift, ...volShift };
      }
      return shift;
    });
  },
  saveVolShiftFailure(state, error) {
    state.allpendingshifts = { ...state.allpendingshifts, error };
  },
};

export const volunteer = {
  namespaced: true,
  state,
  actions,
  mutations,
};
